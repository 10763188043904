/* eslint-disable react/no-unescaped-entities */
import * as React from "react";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export function Terms(): React.JSX.Element {
	return (
		<Container
			maxWidth="lg"
			sx={{ py: 4 }}>
			<Stack spacing={2}>
				<Typography
					color="text.primary"
					variant="h1">
					Terms of Service
				</Typography>
				<Typography
					color="text.primary"
					variant="h4">
					Definitions
				</Typography>
				<Typography>
					The following definitions apply to this Agreement:
				</Typography>
				<Typography>
					<strong>"us", "we", or "our":</strong> MangoMap Limited
				</Typography>
				<Typography>
					<strong>"you", "your", "Customer":</strong> you, an individual or
					organization;
				</Typography>
				<Typography>
					<strong>"Order Form"</strong>: means an order form agreed and executed
					by both parties, setting out the Services ordered by the Customer or
					changes to the Services ordered by either Party;
					<br />
					<strong>"Services":</strong> means the services provided by MangoMap
					as set out in an Order Form and the service accessible at
					www.mangomap.com (https://www.mangomap.com);
				</Typography>
				<Typography>
					<strong>"User Data"</strong>: business data and other information
					related to you, your customers, or your business;
				</Typography>
				<Typography>
					<strong>"Account"</strong>: the access point that a Customer has to
					the Services, comprising a unique username (or email address) and a
					password;
				</Typography>
				<Typography>
					<strong>"Subscription"</strong>: the contract or agreement between the
					Customer and us, where a regular recurring fee is paid in exchange for
					access to the Services;
				</Typography>
				<Typography>
					<strong>"Billing Cycle"</strong>: refers to the recurring time period
					for which a Customer is billed for the use of the Services;
				</Typography>
				<Typography>
					<strong>"Standard Terms"</strong>: means these terms and conditions;
				</Typography>
				<Typography>
					If there is any conflict between the terms of this Agreement, the
					following order of priority shall apply:
				</Typography>
				<ol>
					<li>Order Form(s); and</li>
					<li>Standard Terms.</li>
				</ol>
				<Typography>
					To access the Services, Customers must at all times agree to and abide
					by these Terms. The Services allow you to submit, store, User Data.
					These Terms apply to all visitors, Customers and others who access or
					use the Service.
				</Typography>
				<Typography>
					By accessing or using the Services you agree to be bound by these
					Terms. If you disagree with any part of the terms then you may not
					access the Services.
				</Typography>
				<Typography
					color="text.primary"
					variant="h4">
					Accounts
				</Typography>
				<Typography>
					When you create an account with us, you must provide us information
					that is accurate, complete, and current at all times. You must provide
					your legal full name, a valid email address, and any other information
					requested during the Account creation process. Failure to do so
					constitutes a breach of the Terms, which may result in immediate
					termination of your Account.
				</Typography>
				<Typography>
					You are responsible for safeguarding the password that you use to
					access the Services and for any activities or actions under your
					password, whether your password is with our Services or a third-party
					service. MangoMap Limited cannot and will not be liable for any loss
					or damage from your failure to comply with this security obligation.
				</Typography>
				<Typography>
					You agree not to disclose your password to any third party. You must
					notify us immediately upon becoming aware of any breach of security or
					unauthorized use of your account.
				</Typography>
				<Typography>
					You must be 13 years or older to create an Account using this
					Services, and you must be human. Accounts registered by "bots" or
					other automated methods are not permitted.
				</Typography>
				<Typography>
					Your login credentials may only be used by one person – a single
					Account shared by multiple people is not permitted.
				</Typography>
				<Typography>
					All User Data uploaded to the Services and activity that occurs under
					your Account (even when User Data is posted by others who have access
					to your account through additional login credentials) is solely your
					responsibility. The Service must not be used for any illegal or
					unauthorized purpose. You must not, in the use of the Service, violate
					any laws in your jurisdiction (including but not limited to copyright
					laws).
				</Typography>
				<Typography>
					You must not upload, post, host, or transmit unsolicited email, SMSs,
					or "spam" messages. You must not transmit any worms or viruses or any
					code of a destructive nature. You must not modify, adapt or hack the
					Services or modify another website so as to falsely imply that it is
					associated with the Services, MangoMap Limited, or any other Mango
					service.
				</Typography>
				<Typography>
					You agree not to reproduce, duplicate, copy, sell, resell or exploit
					with malicious intent any portion of the Services, use of the
					Services, or access to the Services without the express written
					permission by MangoMap Limited.
				</Typography>
				<Typography>
					Verbal, physical, written or other abuse (including threats of abuse
					or retribution) of any customer, employee, member, or officer of
					MangoMap Limited will result in immediate Account termination.
				</Typography>
				<Typography>
					We may, but have no obligation to, terminate Accounts containing User
					Data that we determine in our sole discretion are unlawful, offensive,
					threatening, libellous, defamatory, pornographic, obscene or otherwise
					objectionable or violates any party’s intellectual property or these
					Terms of Services.
				</Typography>
				<Typography
					color="text.primary"
					variant="h4">
					Partners
				</Typography>
				<Typography>
					A "Partner" is an individual or entity that has agreed to these Terms
					of Service and participates in the Mango Partner Program.
				</Typography>
				<ul>
					<li>
						The Partner will comply with all applicable laws and highest
						industry standards.
					</li>
					<li>
						The Partner must:
						<ol type="i">
							<li>
								be at least 18 years old or the age of majority where Partner
								resides;
							</li>
							<li>
								provide certain Personal Information necessary for registration
								and authentication, and agree that email will be our primary
								method of communication;
							</li>
							<li>
								make certain acknowledgments if signing up on behalf of an
								employer or a company;
							</li>
							<li>
								agree that this Agreement may be changed by MangoMap Limited at
								any time;
							</li>
							<li>
								acknowledge and agree to MangoMap Limited's Privacy Policy; and
							</li>
							<li>
								not use MangoMap Limited’s trademarks as part of its name or
								brand unless permitted under these Terms; and
							</li>
							<li>
								not buy search engine advertising, trademarks or domain names
								that mention or use "Mango" or other Mango logos.
							</li>
						</ol>
					</li>
					<li>
						Either MangoMap Limited or Partner can end this agreement at any
						time by providing notice to the other. If there is fraud or any
						other unacceptable behaviour by Partner, or if Partner violates
						these Terms of Service, MangoMap Limited can suspend Partner’s
						privileges or end the agreement without notice. MangoMap Limited can
						change or eliminate all or any portion of the Partner Program at any
						time, upon reasonable notice.
					</li>
					<li>
						All of the information, property and intellectual property you have
						access to as a result of your participation in the Mango Partner
						Program belongs entirely to MangoMap Limited.
					</li>
					<li>
						Both MangoMap Limited and Partner agree to use Confidential
						Information and Personal Information only to perform the obligations
						of these Terms. Confidential Information and Personal Information
						must be protected and respected.
					</li>
				</ul>
				<Typography
					color="text.primary"
					variant="h4">
					Subscriptions
				</Typography>
				<Typography>
					Some parts of the Services are billed on a Subscription basis You will
					be billed in advance on an agreed recurring and periodic Billing
					Cycle. Billing Cycles are set either on a monthly, quarterly, annual,
					or multi-year basis, depending on the type of subscription plan you
					select when purchasing a Subscription.
				</Typography>
				<Typography>
					At the end of each Billing Cycle, your Subscription will automatically
					renew under the exact same conditions unless you cancel it or we
					cancel it. You may terminate your Subscription renewal by closing your
					account in your Account panel.
				</Typography>
				<Typography>
					A valid payment method, such as credit card, is required to process
					the payment for your Subscription. You shall provide us with accurate
					and complete billing information including full name and a valid
					payment method information. By submitting such payment information,
					you automatically authorize us to charge all Subscription fees
					incurred through your account to any such payment instruments.
				</Typography>
				<Typography
					color="text.primary"
					variant="h4">
					Fees and Modifications to the Services
				</Typography>
				<Typography>
					We, in our sole discretion and at any time, may modify or discontinue,
					temporarily or permanently, the Services (or any part thereof) with or
					without notice.
				</Typography>
				<Typography>
					We, in our sole discretion and at any time, may modify the
					Subscription fees for any Subscription or optional add-on. Any fee
					change will become effective at the end of the then-current Billing
					Cycle.
				</Typography>
				<Typography>
					We will provide you with a reasonable prior notice of 30 days any
					change in Subscription fees to give you an opportunity to terminate
					your Subscription before such change becomes effective.
				</Typography>
				<Typography>
					Your continued use of the Services after the Subscription fee change
					comes into effect constitutes your agreement to pay the modified
					Subscription fee amount.
				</Typography>
				<Typography>
					Mango shall not be liable to you or to any third party for any
					modification, price change, suspension, or discontinuance of the
					Services.
				</Typography>
				<Typography
					color="text.primary"
					variant="h4">
					Refunds, Upgrades, and Downgrades
				</Typography>
				<Typography>
					All charges are non-refundable unless expressly stated otherwise, or
					otherwise provided by applicable law.
				</Typography>
				<Typography>
					The Services is billed in advance as per your chosen Billing Cycle and
					is non-refundable. There will be no refunds or credits for partial
					periods of usage, upgrade or downgrade, or refunds for any unused
					period with an Active account. In order to treat everyone equally, no
					exceptions will be made.
				</Typography>
				<Typography>
					An upgrade from a trial Account to any paid plan will end your free
					trial. You will be billed for your first Billing Cycle immediately
					upon upgrading.
				</Typography>
				<Typography>
					Upon successful checkout of a purchase or upgrade, any new account
					features of the new plan will be available for use immediately.
				</Typography>
				<Typography>
					Downgrading your Services may cause the loss of User Data, features,
					or capacity of your Account. Mango does not accept any liability for
					any loss incurred as a result of downgrading.
				</Typography>
				<Typography>
					MangoMap Limited is not responsible for any bank fees, interest
					charges, finance charges, overdraft charges, or other fees resulting
					from charges billed for the Services. Currency exchange settlements
					will be based on agreements between you and the provider of your
					credit card.
				</Typography>
				<Typography>
					All Subscription fees and optional Account add-ons are exclusive of
					all taxes, levies, or duties imposed by taxing authorities, and you
					shall be responsible for payment of all such taxes, levies, or duties.
				</Typography>
				<Typography
					color="text.primary"
					variant="h4">
					Cancellation and Termination
				</Typography>
				<Typography>
					You may cancel your Mango account at any time from your Account
					administration panel. We do not provide refunds for any remaining
					credit for your Subscription's Billing Cycle.
				</Typography>
				<Typography>
					Upon cancellation, all User Data associated with the Account will be
					immediately deleted from the Services. Once deleted, User Data cannot
					be recovered.
				</Typography>
				<Typography>
					Mango, in its sole discretion, has the right to suspend or terminate
					your account and refuse any and all current or future use of the
					Services, or any other Mango Services, for any reason at any time.
					Upon suspension or termination, your right to use the Services will
					stop immediately. You may not have access to User Data that you stored
					on the site after we suspend or terminate your account. You are
					responsible for backing up User Data that you use with the Services.
				</Typography>
				<Typography>
					Termination of the Services will result in the deletion of your User
					Data or your access to your Account, and the forfeiture and
					relinquishment of all User Data in your Account. Mango reserves the
					right to refuse service to anyone for any reason at any time.
				</Typography>
				<Typography
					color="text.primary"
					variant="h4">
					Limitation of Liability
				</Typography>
				<Typography>
					You expressly understand and agree that Mango shall not be liable for
					any direct, indirect, incidental, special, consequential or exemplary
					damages, including but not limited to, damages for loss of profits,
					goodwill, use, data or other intangible losses (even if Mango has been
					advised of the possibility of such damages), resulting from:
				</Typography>
				<ol type="i">
					<li>the use or the inability to use the Services;</li>
					<li>
						the cost of procurement of substitute goods and services resulting
						from any goods, data, information or services purchased or obtained
						or messages received or transactions entered into through or from
						the Services;
					</li>
					<li>
						unauthorized access to or alteration of your transmissions or data;
					</li>
					<li>
						statements or conduct of any third party access through the
						Services;
					</li>
					<li>or any other matter relating to the Services.</li>
				</ol>
				<Typography
					color="text.primary"
					variant="h4">
					Disclaimer
				</Typography>
				<Typography>
					Your use of the Service is at your sole risk. The Service is provided
					on an &quot;as is&quot; and &quot;as available&quot; basis.
				</Typography>
				<Typography>
					The Service is provided without warranties of any kind, whether
					express or implied, including, but not limited to, implied warranties
					of merchantability, fitness for a particular purpose, non-infringement
					or course of performance.
				</Typography>
				<Typography>
					MangoMap Limited, its subsidiaries, affiliates, and its licensors do
					not warrant that
				</Typography>
				<ol type="i">
					<li>the service will meet your specific requirements;</li>
					<li>
						the service will be uninterrupted, timely, secure, or error-free;
					</li>
					<li>
						the results that may be obtained from the use of the service will be
						accurate or reliable;
					</li>
					<li>
						the quality of any products, services, information, or other
						material purchased or obtained by you through the service will meet
						your expectations; and
					</li>
					<li>any errors in the Service will be corrected.</li>
				</ol>
				<Typography>
					While MangoMap Limited prohibits such conduct and User Data on the
					Service, you understand and agree that MangoMap Limited cannot be
					responsible for the User Data posted on the Service and you
					nonetheless may be exposed to such materials.
				</Typography>
				<Typography
					color="text.primary"
					variant="h4">
					Copyright and Content Ownership
				</Typography>
				<Typography>
					We claim no intellectual property rights over the User Data or
					material you provide to the Service. Your Personal Information and
					User Data uploaded remain yours. However, by opting to make your User
					Data public and available to anonymous users through configuration of
					dataset access settings to a public state, you agree to allow others
					to view, access, or download your User Data without restriction. By
					opting to configure your maps as online and to be viewed publicly
					without access control, you agree to allow others to view your maps.
				</Typography>
				<Typography>
					Mango does not pre-screen User Data, but Mango and its designee have
					the right (but not the obligation) in their sole discretion to refuse
					or remove any User Data that is available via the Service.
					<br />
					<br />
					In addition, we are not a content-archiving service. We do not promise
					to store or make available on our Services any User Data that you
					post, or any other content, for any length of time. You are solely
					responsible for keeping back-ups of everything you post on our
					Services.
				</Typography>
				<Typography
					color="text.primary"
					variant="h4">
					Digital Millennium Copyright Act Compliance
				</Typography>
				<Typography>
					If you are a copyright owner or an agent thereof, and believe that any
					User Data or other material infringes upon your copyrights, you may
					submit a notification pursuant to the Digital Millennium Copyright Act
					("DMCA") by providing our Copyright Agent with the following
					information in writing (see&nbsp;
					<Link
						href="http://www.copyright.gov/title17/92chap5.html#512"
						rel="noopener"
						target="_blank">
						17 U.S.C § 512(c)(3)
					</Link>
					&nbsp;for further detail):
				</Typography>
				<ul>
					<li>
						A physical or electronic signature of a person authorized to act on
						behalf of the owner of an exclusive right that is allegedly
						infringed;
					</li>
					<li>
						Identification of the copyrighted work claimed to have been
						infringed, or, if multiple copyrighted works on the Services are
						covered by a single notification, a representative list of such
						works from the Services;
					</li>
					<li>
						Identification of the material that is claimed to be infringing or
						to be the subject of infringing activity and that is to be removed
						or access to which is to be disabled, and information reasonably
						sufficient to permit us to locate the material;
					</li>
					<li>
						Information reasonably sufficient to permit us to contact the
						complaining party, such as an address, telephone number, and, if
						available, an electronic mail address;
					</li>
					<li>
						A statement that the complaining party has a good faith belief that
						use of the material in the manner complained of is not authorized by
						the copyright owner, its agent, or the law; and
					</li>
					<li>
						A statement that the information in the notification is accurate,
						and under penalty of perjury, that the complaining party is
						authorized to act on behalf of the owner of an exclusive right that
						is allegedly infringed.
					</li>
				</ul>
				<Typography>
					The designated Copyright Agent to receive notifications of claimed
					infringement is:
				</Typography>
				<Typography>
					MangoMap Limited,&nbsp;
					<Link
						href="mailto:support@mangomap.com"
						rel="noopener"
						target="_blank">
						support@mangomap.com
					</Link>
				</Typography>
				<Typography
					color="text.primary"
					variant="h4">
					Fair Usage Policy
				</Typography>
				<Typography>
					Accounts can have up to 50,000 map views per month under our fair
					usage policy. Customers who think their maps will exceed this level
					regularly should contact Mango support.
				</Typography>
				<Typography>
					If your bandwidth usage significantly exceeds the average bandwidth
					usage (as determined solely by us) of others accessing of the
					Services, we reserve the right to immediately disable your account or
					throttle your file hosting until you can reduce your bandwidth
					consumption.
				</Typography>
				<Typography
					color="text.primary"
					variant="h4">
					Waiver
				</Typography>
				<Typography>
					The failure of MangoMap Limited to exercise or enforce any right or
					provision of these Terms will not constitute a waiver of such right or
					provision. Any waiver of any provision of these Terms will be
					effective only if in writing and signed by an authorized
					representative of MangoMap Limited.
					<br />
					If any provision of these Terms is held to be invalid or unenforceable
					by a court, the remaining provisions of these Terms will remain in
					effect. These Terms constitute the entire agreement between you and us
					and govern your use of the Services, superseding any prior agreements
					between you and us (including, but not limited to, any prior versions
					of these Terms of Services).
				</Typography>
				<Typography
					color="text.primary"
					variant="h4">
					Notice of modification
				</Typography>
				<Typography>
					MangoMap Limited reserves the right to update and change these Terms
					of Service from time to time without notice. Any new features that
					augment or enhance the current Services, including the release of new
					tools and resources, shall be subject to the Terms of Service.
					Continued use of the Service after any such changes shall constitute
					your consent to such changes. You can review the most current version
					of the Terms of Service at any time.
				</Typography>
				<Typography
					color="text.primary"
					variant="h4">
					Indemnification
				</Typography>
				<Typography>
					You agree to indemnify and hold harmless MangoMap Limited and its
					respective directors, officers, employees and agents from and against
					any and all claims and expenses, including attorneys’ fees, arising
					out of your use of the Services, including but not limited to your
					violation of the Terms.
				</Typography>
				<Typography
					color="text.primary"
					variant="h4">
					Support
				</Typography>
				<Typography>
					Our commitment to providing outstanding customer support is a
					cornerstone of our Services. Support requests can be submitted through
					our Help Centre which can be found at&nbsp;
					<Link
						href="https://help.mangomap.com/"
						rel="noopener noreferrer"
						target="_blank">
						https://help.mangomap.com
					</Link>
					.
				</Typography>
				<Typography>
					The expected response times for each of our plans are as follows:
				</Typography>
				<Typography>
					<strong>
						Tier 1:<span>&nbsp;</span>
					</strong>
					Our Agency plan customers enjoy our fastest support responses, with
					replies typically sent within 12 hours of the request submitted
					through the Mango Help Centre.
				</Typography>
				<Typography>
					<strong>
						Tier 2:<span>&nbsp;</span>
					</strong>
					As an Enterprise plan customer, you'll benefit from our priority
					support, and we aim to respond to your queries, submitted via the
					Mango Help Centre, within one business day.
				</Typography>
				<Typography>
					<strong>
						Tier 3:<span>&nbsp;</span>
					</strong>
					For our Business plan customers, we strive to respond to all support
					requests, submitted through the Mango Help Centre, within two business
					days.
				</Typography>
				<Typography>
					<strong>
						Tier 4:<span>&nbsp;</span>
					</strong>
					If you're on our Professional plan, you can expect to receive a
					response within three business days after submitting a support request
					via the Mango Help Centre.
				</Typography>
				<Typography>
					Please note that these times are maximum estimates and we always
					strive to respond to our customers as soon as possible.
				</Typography>
				<Typography>
					For our Enterprise and Agency plan customers, phone support is
					available by appointment. This allows us to ensure we provide timely
					and effective phone assistance. Unfortunately, phone support is
					currently not available for Professional and Business plans.
				</Typography>
				<Typography>
					These published response times apply to standard business days and
					hours, and might be affected by national holidays. We appreciate your
					understanding and are dedicated to assisting you as quickly and
					effectively as possible.
				</Typography>
				<Typography
					color="text.primary"
					variant="h4">
					Data Security
				</Typography>
				<Typography>
					You understand and accept that MangoMap Limited uses third party
					vendors and hosting partners to provide the necessary hardware,
					software, networking, storage, and related technology required to run
					the Services.
				</Typography>
				<Typography>
					You understand and accept that the technical processing and
					transmission of the Services, including your User Data, may be
					transferred unencrypted and involve
				</Typography>
				<ol type="i">
					<li>transmissions over various networks; and</li>
					<li>
						changes to conform and adapt to technical requirements of connecting
						networks or devices.
					</li>
				</ol>
				<Typography>
					For further details about the Data Security processes in place at
					Mango, please see&nbsp;
					<Link
						href="https://help.mangomap.com/hc/en-us/articles/360022305211"
						target="_self">
						Data Security, Processing &amp; Storage
					</Link>
					.
				</Typography>
				<Typography
					color="text.primary"
					variant="h4">
					Contact
				</Typography>
				<Typography>
					If you have any questions, comments or requests regarding the Terms of
					Service, please email{" "}
					<Link
						href="mailto:support@mangomap.com"
						target="_blank">
						support@mangomap.com
					</Link>
					.
				</Typography>
			</Stack>
			<Stack
				spacing={2}
				sx={{ py: 4 }}>
				<Typography variant="h6">
					How can you contact us about this notice?
				</Typography>

				<Typography>
					If you have any questions or concerns about these Terms of Service
					please contact us.
				</Typography>

				<Typography>
					support@mangomap.com
					<br />
					1 Victoria Street
					<br />
					Bristol, BS1 6AA
					<br />
					United Kingdom
				</Typography>
			</Stack>
		</Container>
	);
}
