import * as React from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import {
	ArrowClockwise,
	Rocket,
	UsersThree,
	Wallet,
} from "@phosphor-icons/react";

export function Benefits(): React.JSX.Element {
	const benefits = {
		heading: "Slash costs, keep the quality",
		subHeading:
			"Tired of steep fees eating your budget? See how Mango delivers professional mapping power that saves you money without sacrificing the quality you demand.",
		cards: [
			{
				eyebrow: "Smart Savings",
				icon: <Wallet />,
				title: "Affordable GIS without compromise",
				description:
					"Mango cuts out ESRI's steep licensing fees and hidden extras, offering pro-grade mapping at a fraction of the cost—perfect for budget-conscious GIS pros.",
				image: "/new-mangomap-assets/benefit-fixed-price.jpg",
			},
			{
				eyebrow: "Instant Access",
				icon: <Rocket />,
				title: "Ditch the delivery delays",
				description:
					"With one click, send secure, interactive maps to clients or teams. No more slow emails or static files—just instant access that saves you time and impresses stakeholders.",
				image: "/new-mangomap-assets/benefit-share.jpg",
			},
			{
				eyebrow: "Effortless",
				icon: <ArrowClockwise />,
				title: "No need to start over",
				description:
					"Mango integrates smoothly with your existing GIS tools and data formats, letting you dive in without wrestling new tech or disrupting your setup.",
				image: "/new-mangomap-assets/benefit-integrate.jpg",
			},
			{
				eyebrow: "Collaboration",
				icon: <UsersThree />,
				title: "Precision access for teams",
				description:
					"Control who sees or edits your maps with simple, no-cost team management. Add colleagues or clients instantly, keeping everyone on the same page effortlessly.",
				image: "/new-mangomap-assets/benefit-team.jpg",
			},
		],
	};

	return (
		<Box
			sx={{
				bgcolor: "var(--mui-palette-neutral-100)",
				color: "var(--mui-palette-common-white)",
				overflow: "hidden",
				py: "120px",
				position: "relative",
			}}>
			<Box
				sx={{
					alignItems: "center",
					display: "flex",
					height: "100%",
					justifyContent: "center",
					left: 0,
					position: "absolute",
					top: 0,
					width: "100%",
					zIndex: 0,
				}}>
				<Box
					component="img"
					src="/new-mangomap-assets/home-cosmic.svg"
					sx={{ height: "auto", width: "1600px" }}
				/>
			</Box>
			<Stack
				spacing={8}
				sx={{ position: "relative", zIndex: 1 }}>
				<Container maxWidth="md">
					<Stack spacing={2}>
						<Typography
							color="text.primary"
							sx={{ textAlign: "center" }}
							variant="h3">
							{benefits.heading}
						</Typography>
						<Typography
							color="text.secondary"
							sx={{ textAlign: "center" }}>
							{benefits.subHeading}
						</Typography>
					</Stack>
				</Container>
				<Container maxWidth="lg">
					{benefits.cards.map((card, index) => {
						const isEven = index % 2 === 0;

						return (
							<Grid
								alignItems="center"
								container
								key={card.eyebrow}
								spacing={3}
								sx={{ mb: index < benefits.cards.length - 1 ? 3 : 0 }}>
								{/* Text Section */}
								<Grid
									md={5}
									sx={{
										order: {
											xs: 1,
											md: isEven ? 0 : 1,
										},
									}}
									xs={12}>
									<Stack spacing={2}>
										<Box
											sx={{
												display: "flex",
												justifyContent: {
													xs: "center",
													md: isEven ? "flex-start" : "flex-end",
												},
											}}>
											<Chip
												color="success"
												icon={card.icon}
												label={card.eyebrow}
												size="small"
												variant="soft"
											/>
										</Box>
										<Typography
											color="text.primary"
											sx={{
												textAlign: {
													xs: "center",
													md: isEven ? "left" : "right",
												},
											}}
											variant="h3">
											{card.title}
										</Typography>
										<Typography
											color="text.secondary"
											sx={{
												textAlign: {
													xs: "center",
													md: isEven ? "left" : "right",
												},
											}}>
											{card.description}
										</Typography>
									</Stack>
								</Grid>

								{/* Image Section */}
								<Grid
									md={7}
									sx={{
										order: {
											xs: 2,
											md: isEven ? 1 : 0,
										},
									}}
									xs={12}>
									<Box
										sx={{
											margin: "0 auto",
											maxWidth: "100%",
											position: "relative",
											width: "390px",
										}}>
										<Box
											alt={card.title}
											component="img"
											src={card.image}
											sx={{
												height: "auto",
												position: "relative",
												width: "100%",
												zIndex: 1,
												borderRadius: "20px",
											}}
										/>
									</Box>
								</Grid>
							</Grid>
						);
					})}
				</Container>
			</Stack>
		</Box>
	);
}
