import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { FranchiseContactForm } from "~landing/components/franchise/contact-form";

export function StartBuilding(): React.JSX.Element {
	return (
		<Box sx={{ bgcolor: "var(--mui-palette-background-level1)", pb: "60px" }}>
			<Container>
				<Box
					sx={{
						borderRadius: "20px",
						border: "1px solid transparent",
						color: "var(--mui-palette-common-white)",
						display: "flex",
						flexDirection: { xs: "column", md: "row" },
						overflow: "hidden",
						position: "relative",
					}}>
					<Box
						sx={{
							bgcolor: "var(--mui-palette-neutral-950)",
							borderRadius: "20px",
							bottom: "1px",
							left: "-1px",
							position: "absolute",
							right: "1px",
							top: "-1px",
							zIndex: 0,
						}}>
						<Box
							sx={{
								alignItems: "center",
								bottom: 0,
								display: "flex",
								justifyContent: "center",
								left: 0,
								position: "absolute",
								right: 0,
								top: 0,
								zIndex: 0,
							}}>
							<Box
								component="img"
								src="/new-mangomap-assets/home-cosmic.svg"
								sx={{ height: "auto", width: "1600px" }}
							/>
						</Box>
						<Box
							sx={{
								alignItems: "center",
								bottom: 0,
								display: "flex",
								justifyContent: "center",
								left: 0,
								position: "absolute",
								right: 0,
								top: 0,
								zIndex: 1,
							}}>
							<Box
								component="img"
								src="/new-mangomap-assets/home-rectangles.svg"
								sx={{ height: "auto", width: "1900px" }}
							/>
						</Box>
					</Box>
					<Stack
						direction={{ xs: "column", md: "row" }}
						spacing={3}
						sx={{
							flex: "0 1 auto",
							position: "relative",
							px: "64px",
							py: "120px",
							zIndex: 1,
						}}>
						<Box sx={{ flex: 1 }}>
							<Stack spacing={2}>
								<Typography
									color="inherit"
									variant="h3">
									Gain a competitive edge today.
								</Typography>
								<Typography color="neutral.300">
									Get in touch today to see exactly how Mango can assist your
									franchise development plans.
								</Typography>
							</Stack>
						</Box>
						<Box sx={{ flex: 1 }}>
							<Stack spacing={2}>
								<FranchiseContactForm />
							</Stack>
						</Box>
					</Stack>
				</Box>
			</Container>
		</Box>
	);
}
