import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Lifebuoy } from "@phosphor-icons/react";

import { ContactForm } from "~landing/components/contact/contact-form";

export function Contact(): React.JSX.Element {
	const contactContent = {
		heading: "Elevate your GIS capabilities.",
		subHeading:
			"Reach out today to discover how Mango's innovative mapping solutions can streamline your workflows and transform your projects.",
	};

	return (
		<Box sx={{ bgcolor: "var(--mui-palette-background-level1)", py: "60px" }}>
			<Container>
				<Box
					sx={{
						borderRadius: "20px",
						border: "1px solid transparent",
						color: "var(--mui-palette-common-white)",
						display: "flex",
						flexDirection: { xs: "column", md: "row" },
						overflow: "hidden",
						position: "relative",
					}}>
					<Box
						sx={{
							bgcolor: "var(--mui-palette-neutral-950)",
							borderRadius: "20px",
							bottom: "1px",
							left: "-1px",
							position: "absolute",
							right: "1px",
							top: "-1px",
							zIndex: 0,
						}}>
						<Box
							sx={{
								alignItems: "center",
								bottom: 0,
								display: "flex",
								justifyContent: "center",
								left: 0,
								position: "absolute",
								right: 0,
								top: 0,
								zIndex: 0,
							}}>
							<Box
								component="img"
								src="/new-mangomap-assets/home-cosmic.svg"
								sx={{ height: "auto", width: "1600px" }}
							/>
						</Box>
						<Box
							sx={{
								alignItems: "center",
								bottom: 0,
								display: "flex",
								justifyContent: "center",
								left: 0,
								position: "absolute",
								right: 0,
								top: 0,
								zIndex: 1,
							}}>
							<Box
								component="img"
								src="/new-mangomap-assets/home-rectangles.svg"
								sx={{ height: "auto", width: "1900px" }}
							/>
						</Box>
					</Box>
					<Stack
						direction={{ xs: "column", md: "row" }}
						spacing={3}
						sx={{
							flex: "0 1 auto",
							position: "relative",
							px: "64px",
							py: "64px",
							zIndex: 1,
						}}>
						<Box sx={{ flex: 1 }}>
							<Stack spacing={2}>
								<Typography
									color="inherit"
									variant="h3">
									{contactContent.heading}
								</Typography>
								<Typography color="neutral.300">
									{contactContent.subHeading}
								</Typography>
							</Stack>
							<Box sx={{ flex: 1 }}>
								<Stack
									mt={4}
									spacing={2}>
									<Stack
										alignItems="center"
										direction="row"
										spacing={1}>
										<Lifebuoy
											size={24}
											weight="duotone"
										/>
										<Typography
											color="inherit"
											variant="subtitle2">
											Need technical support?{" "}
											<Link
												href="https://help.mangomap.com/hc/en-us/requests/new"
												target="_blank">
												Submit a ticket
											</Link>
										</Typography>
									</Stack>
								</Stack>
							</Box>
						</Box>

						<Box sx={{ flex: 1 }}>
							<Stack spacing={2}>
								<ContactForm />
							</Stack>
						</Box>
					</Stack>
				</Box>
			</Container>
		</Box>
	);
}
