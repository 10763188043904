import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
	CaretLeft as CaretLeftIcon,
	CaretRight as CaretRightIcon,
	Quotes as QuoteIcon,
	Users as UsersIcon,
} from "@phosphor-icons/react";
import useEmblaCarousel from "embla-carousel-react";
import * as React from "react";

import type { EmblaCarouselType } from "embla-carousel";

const reviews = [
	{
		id: "REV-5",
		author: "Raheem Hamilton",
		title: "Anytime Fitness",
		logo: "https://mangomap.com/new-mangomap-assets/images/raheem.jpeg",
		comment:
			"Mango is a great tool to fast track the growth of your business. Great support from Paul and the team, would highly recommend.",
		outcome:
			"ARC reduced manual updates by 70% and used our dynamic portal to win additional grants for habitat restoration—impressing both clients and donors.",
	},
	{
		id: "REV-4",
		author: "Lauren Aten",
		title: "Dealer Support, ForeverLawn",
		logo: "https://mangomap.com/new-mangomap-assets/images/lauren.jpeg",
		comment:
			"Mango does a great job of providing the outline of territories and having the ability to search through zip code.",
		outcome:
			"Ridge to Reefs slashed data prep time by 60% and showcased drone insights in a portal that secured $50K for coastal restoration.",
	},

	{
		id: "REV-2",
		author: "Jarrad Goulding",
		title: "Founder, The Local Guys",
		logo: "https://mangomap.com/new-mangomap-assets/images/jarrad.jpeg",
		comment:
			"I found Mango to be the best option for us. I like how user-friendly it is. The ability for my regional partners & potential candidates to log in is very helpful. All in all, I’m very happy with the software!",
		outcome:
			"EPR simplified complex analyses into a user-friendly portal, boosting client retention by 30% with faster renewals.",
	},
	{
		id: "REV-1",
		author: "David Ranallo",
		title: "Balanced Family Academy",
		logo: "https://mangomap.com/new-mangomap-assets/images/bfaicon.png",
		comment:
			"Easy to setup, GREAT support team that responded quickly early on and then the tool itself was very easy to use and produced desired results. This is how software should be. Legit!",
		outcome:
			"AR Consultores increased stakeholder engagement by 50% with vivid portals, cementing their eco-innovation leadership.",
	},
	{
		id: "REV-1",
		author: "Charlotte Saunders",
		title: "Little Learners",
		logo: "https://mangomap.com/new-mangomap-assets/images/littlelearnersicon.png",
		comment:
			"The Mango team is always around and willing to help and we couldn't be happier that we are customers. We would recommend them to anyone looking for this software.",
		outcome:
			"AR Consultores increased stakeholder engagement by 50% with vivid portals, cementing their eco-innovation leadership.",
	},
] as const;

export function Testimonials(): React.JSX.Element {
	const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });
	const [prevBtnDisabled, setPrevBtnDisabled] = React.useState(true);
	const [nextBtnDisabled, setNextBtnDisabled] = React.useState(true);
	const [selectedIndex, setSelectedIndex] = React.useState(0);
	const [scrollSnaps, setScrollSnaps] = React.useState<number[]>([]);

	const scrollPrev = React.useCallback(() => {
		emblaApi?.scrollPrev();
	}, [emblaApi]);

	const scrollNext = React.useCallback(() => {
		emblaApi?.scrollNext();
	}, [emblaApi]);

	const scrollTo = React.useCallback(
		(index: number) => {
			emblaApi?.scrollTo(index);
		},
		[emblaApi],
	);

	const onInit = React.useCallback((api: EmblaCarouselType) => {
		setScrollSnaps(api.scrollSnapList());
	}, []);

	const onSelect = React.useCallback((api: EmblaCarouselType) => {
		setSelectedIndex(api.selectedScrollSnap());
		setPrevBtnDisabled(!api.canScrollPrev());
		setNextBtnDisabled(!api.canScrollNext());
	}, []);

	React.useEffect(() => {
		if (!emblaApi) return;

		onInit(emblaApi);
		onSelect(emblaApi);
		emblaApi.on("reInit", onInit);
		emblaApi.on("reInit", onSelect);
		emblaApi.on("select", onSelect);
	}, [emblaApi, onInit, onSelect]);

	return (
		<Box
			sx={{
				bgcolor: "var(--mui-palette-background-level1)",
				borderTop: "1px solid var(--mui-palette-divider)",
				borderBottom: "1px solid var(--mui-palette-divider)",
				py: "120px",
			}}>
			<Stack
				spacing={6}
				sx={{ maxWidth: "1200px", mx: "auto", px: 3 }}>
				<Stack
					spacing={2}
					sx={{ textAlign: "center" }}>
					<Box sx={{ display: "flex", justifyContent: "center" }}>
						<Chip
							color="primary"
							icon={<UsersIcon />}
							label="Success stories"
							variant="soft"
						/>
					</Box>
					<Typography variant="h3">What County Officials Are Saying</Typography>
					<Typography
						color="text.secondary"
						sx={{ maxWidth: "700px", mx: "auto" }}
						variant="body1">
						Real results from real counties that switched to Mango Maps
					</Typography>
				</Stack>

				<Box sx={{ position: "relative" }}>
					<Box
						ref={emblaRef}
						sx={{
							overflow: "hidden",
							borderRadius: 2,
						}}>
						<Box
							sx={{
								display: "flex",
								touchAction: "pan-y",
							}}>
							{reviews.map((review) => (
								<Box
									key={review.id}
									sx={{
										flex: "0 0 100%",
										minWidth: 0,
										position: "relative",
										px: 2,
									}}>
									<Paper
										elevation={3}
										sx={{
											borderRadius: 4,
											overflow: "hidden",
											height: "100%",
											display: "flex",
											flexDirection: { xs: "column", md: "row" },
											alignItems: "stretch",
										}}>
										<Box
											sx={{
												bgcolor: "primary.main",
												color: "primary.contrastText",
												p: 4,
												display: "flex",
												flexDirection: "column",
												justifyContent: "center",
												alignItems: "center",
												width: { xs: "100%", md: "35%" },
											}}>
											<Stack
												alignItems="center"
												spacing={3}>
												<Avatar
													alt={`${review.author} logo`}
													src={review.logo}
													sx={{
														width: 80,
														height: 80,
													}}
												/>
												<Stack
													alignItems="center"
													spacing={1}>
													<Typography variant="h6">{review.author}</Typography>
													<Typography variant="body2">
														{review.title}
													</Typography>
												</Stack>
											</Stack>
										</Box>

										<Box
											sx={{
												p: 4,
												flex: 1,
												display: "flex",
												flexDirection: "column",
												justifyContent: "center",
											}}>
											<Stack spacing={3}>
												<Box sx={{ display: "flex" }}>
													<QuoteIcon
														color="var(--mui-palette-primary-dark)"
														size={32}
													/>
												</Box>
												<Typography variant="h4">{review.comment}</Typography>
												<Typography
													sx={{
														p: 2,
														bgcolor: "action.hover",
														borderRadius: 2,
														borderColor: "primary.main",
													}}
													variant="body1">
													<strong>Result:</strong> {review.outcome}
												</Typography>
											</Stack>
										</Box>
									</Paper>
								</Box>
							))}
						</Box>
					</Box>

					<Box
						sx={{
							position: "absolute",
							top: "50%",
							transform: "translateY(-50%)",
							left: { xs: "15%", sm: 0 },
							ml: { xs: "-80px", sm: "-20px" },
							mt: { xs: "180px", sm: 0 },
							zIndex: 1,
						}}>
						<IconButton
							disabled={prevBtnDisabled}
							onClick={scrollPrev}
							sx={{
								bgcolor: "background.paper",
								boxShadow: 2,
								"&:hover": { bgcolor: "background.paper" },
							}}>
							<CaretLeftIcon weight="bold" />
						</IconButton>
					</Box>

					<Box
						sx={{
							position: "absolute",
							top: "50%",
							transform: "translateY(-50%)",
							right: { xs: "15%", sm: 0 },
							mr: { xs: "-80px", sm: "-20px" },
							mt: { xs: "180px", sm: 0 },
							zIndex: 1,
						}}>
						<IconButton
							disabled={nextBtnDisabled}
							onClick={scrollNext}
							sx={{
								bgcolor: "background.paper",
								boxShadow: 2,
								"&:hover": { bgcolor: "background.paper" },
							}}>
							<CaretRightIcon weight="bold" />
						</IconButton>
					</Box>
				</Box>

				<Stack
					direction="row"
					spacing={1}
					sx={{ justifyContent: "center" }}>
					{scrollSnaps.map((_, index) => (
						<Box
							// eslint-disable-next-line react/no-array-index-key -- index is stable and tied to slide position
							key={index}
							onClick={() => {
								scrollTo(index);
							}}
							sx={{
								bgcolor:
									index === selectedIndex ? "primary.main" : "action.selected",
								borderRadius: "50%",
								cursor: "pointer",
								height: "10px",
								width: "10px",
								transition: "all 0.2s ease",
								"&:hover": {
									bgcolor: "primary.light",
								},
							}}
						/>
					))}
				</Stack>
			</Stack>
		</Box>
	);
}
