import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Leaf } from "@phosphor-icons/react";
import { signUpV1Or2 } from "config/paths";
import RouterLink from "next/link";
import * as React from "react";

export function Hero(): React.JSX.Element {
	return (
		<Box
			sx={{
				bgcolor: "var(--mui-palette-neutral-50)",
				color: "var(--mui-palette-common-white)",
				overflow: "hidden",
				position: "relative",
			}}>
			<Box
				sx={{
					alignItems: "center",
					bottom: 0,
					display: "flex",
					justifyContent: "center",
					left: 0,
					position: "absolute",
					right: 0,
					top: 0,
					zIndex: 0,
				}}>
				<Box
					component="img"
					src="/new-mangomap-assets/home-cosmic.svg"
					sx={{ height: "auto", width: "1600px" }}
				/>
			</Box>
			<Box
				sx={{
					alignItems: "center",
					bottom: 0,
					display: "flex",
					justifyContent: "center",
					left: 0,
					position: "absolute",
					right: 0,
					top: 0,
					zIndex: 1,
				}}>
				<Box
					component="img"
					src="/new-mangomap-assets/home-rectangles.svg"
					sx={{ height: "auto", width: "1900px" }}
				/>
			</Box>
			<Container
				maxWidth="lg"
				sx={{ position: "relative", py: "120px", zIndex: 3 }}>
				<Stack spacing={6}>
					<Stack
						spacing={2}
						sx={{
							justifyContent: "center",
							alignItems: "center",
						}}>
						<div>
							<Chip
								color="success"
								icon={<Leaf />}
								label="Environmental Mapping"
								size="small"
								variant="outlined"
							/>
						</div>
						<Typography
							align="center"
							color="var(--mui-palette-common-black)"
							sx={{ fontSize: "4rem", fontWeight: 700, lineHeight: 1.2 }}
							variant="h1">
							Accelerate your eco-mission.
						</Typography>
						<Typography
							color="text.secondary"
							sx={{ fontWeight: 600, textAlign: "center" }}
							variant="h3">
							A no-code GIS platform for environmental consultants to publish
							stunning, long-term maps that win funding and wow stakeholders.
						</Typography>
					</Stack>
					<Stack
						direction="column"
						spacing={1}
						sx={{ justifyContent: "center", alignItems: "center" }}>
						<div>
							<Button
								component={RouterLink}
								href={signUpV1Or2}
								size="large"
								variant="contained">
								Start Your 30-Day Free Trial
							</Button>
						</div>
						<Typography
							color="neutral.400"
							variant="overline">
							30 day free trial - No credit card needed
						</Typography>
					</Stack>
					<Stack
						direction="row"
						spacing={1}
						sx={{ alignItems: "center", justifyContent: "center " }}>
						<AvatarGroup
							sx={{
								"& .MuiAvatar-root": {
									border: "2px solid var(--mui-palette-neutral-950)",
								},
							}}>
							<Avatar
								alt="User 5"
								src="/new-mangomap-assets/avatar-5.png"
							/>
							<Avatar
								alt="User 1"
								src="/new-mangomap-assets/avatar-1.png"
							/>
							<Avatar
								alt="User 2"
								src="/new-mangomap-assets/avatar-2.png"
							/>
						</AvatarGroup>
						<Typography
							color="neutral.400"
							sx={{ whiteSpace: "nowrap" }}
							variant="caption">
							<Typography
								color="inherit"
								component="span"
								sx={{ fontWeight: 700 }}
								variant="inherit">
								“Cut costs by 30% and secured a major grant in record time.”
							</Typography>{" "}
							— Trusted by eco-warriors at Ridge to Reefs and EPR.
						</Typography>
					</Stack>
				</Stack>
			</Container>
			<Container
				maxWidth="lg"
				sx={{ position: "relative", zIndex: 2 }}>
				<Box
					component="img"
					src="https://mangomap.com/_next/image?url=%2Fnew-mangomap-assets%2Fimages%2FmangoGIS.png&w=1080&q=75"
					sx={{
						display: "block",
						height: "auto",
						position: "relative",
						width: "100%",
						zIndex: 2,
					}}
				/>
			</Container>
			<Container
				maxWidth="xl"
				sx={{ pt: "64px" }}>
				<Stack spacing={3}>
					<Typography
						color="text.secondary"
						component="p"
						sx={{ textAlign: "center" }}
						variant="overline">
						Used by companies like:
					</Typography>
					<Stack
						direction="row"
						spacing={3}
						sx={{
							alignItems: "center",
							borderTop: "1px solid var(--mui-palette-divider)",
							borderBottom: "1px solid var(--mui-palette-divider)",
							color: "var(--mui-palette-neutral-500)",
							flexWrap: "wrap",
							justifyContent: "center",
							py: "16px",
						}}>
						<Stack
							direction="row"
							spacing={6}
							sx={{
								alignItems: "center",
								color: "var(--mui-palette-neutral-500)",
								flexWrap: "wrap",
								justifyContent: "center",
								filter: "grayscale(1) opacity(0.7)",
							}}>
							<Box
								component="img"
								src="https://mangomap.com/_next/image?url=%2Fnew-mangomap-assets%2Fimages%2Fsensar.png&w=128&q=75"
							/>
							<Box
								component="img"
								src="https://mangomap.com/new-mangomap-assets/svg/gis4ag.svg"
								sx={{ height: "50px" }}
							/>
							<Box
								component="img"
								src="https://mangomap.com/_next/image?url=%2Fnew-mangomap-assets%2Fimages%2Fgeotech-info.png&w=128&q=75"
							/>
							<Box
								component="img"
								src="https://mangomap.com/new-mangomap-assets/svg/enerko.svg"
								sx={{ height: "50px" }}
							/>
							<Box
								component="img"
								src="https://mangomap.com/new-mangomap-assets/svg/swift.svg"
								sx={{ height: "50px" }}
							/>
							<Box
								component="img"
								src="https://mangomap.com/new-mangomap-assets/svg/gbi.svg"
								sx={{ height: "50px" }}
							/>
							<Box
								component="img"
								src="https://mangomap.com/_next/image?url=%2Fnew-mangomap-assets%2Fimages%2Fplumas.png&w=128&q=75"
							/>
						</Stack>
					</Stack>
				</Stack>
			</Container>
		</Box>
	);
}
