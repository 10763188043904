import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { useFormik } from "formik";
import { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "ui-components";
import * as Yup from "yup";

import { useSubmitFranchiseContactForm } from "~landing/queries/useSubmitFranchiseContactForm";
import { sendAnalyticsEvent } from "~utils/providers/analytics-provider";

export function FranchiseContactForm() {
	const recaptchaRef = useRef<ReCAPTCHA>(null);
	const submitContactForm = useSubmitFranchiseContactForm();
	const form = useFormik({
		validateOnMount: true,
		initialValues: {
			name: "",
			email: "",
			phone: "",
			company: "",
			isRegistered: true,
			locations: "0",
			goalNumber: "",
			source: "",
			sourceDetails: "",
		},
		validationSchema: Yup.object({
			name: Yup.string().required("Required"),
			email: Yup.string().email("Invalid email address").required("Required"),
			phone: Yup.string().required("Required"),
			company: Yup.string().required("Required"),
			isRegistered: Yup.boolean().required("Required"),
			locations: Yup.string().required("Required"),
			goalNumber: Yup.string().required("Required"),
			source: Yup.string().required("Required"),
			sourceDetails: Yup.string().when("source", (source, schema) => {
				if (isDetailsRequired(source)) {
					return schema.required("Required");
				}

				return schema.notRequired();
			}),
		}),
		onSubmit: (values, helpers) => {
			toast.promise(
				async () => {
					const recaptchaToken = await recaptchaRef.current!.executeAsync();
					if (!recaptchaToken) throw new Error("Failed to get recaptcha token");

					await submitContactForm.mutateAsync({
						input: values,
						recaptchaToken,
					});
					sendAnalyticsEvent("submitContact");
					helpers.resetForm();
				},
				{
					loading: "Submitting...",
					success: "Your message has been sent!",
					error: (e) => {
						console.log("🚀 ~ ContactForm ~ e:", e);
						return "Something went wrong!";
					},
				},
			);
		},
	});

	// Reusable dark input styles for OutlinedInput and Select components.
	const darkInputStyles = {
		color: "white",
		backgroundColor: "rgba(255, 255, 255, 0.1)", // slight translucent background for contrast
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "white",
		},
	};
	return (
		<Box>
			<Stack
				spacing={1}
				component="form"
				onSubmit={form.handleSubmit}>
				<Grid
					container
					spacing={2}>
					<Grid
						sm={12}
						xs={12}>
						<FormControl fullWidth>
							<InputLabel
								required
								sx={{ color: "white" }}>
								Name
							</InputLabel>
							<OutlinedInput
								size="small"
								sx={darkInputStyles}
								{...form.getFieldProps("name")}
								error={!!form.touched.name && !!form.errors.name}
							/>
							{!!form.touched.name && !!form.errors.name && (
								<FormHelperText error>{form.errors.name}</FormHelperText>
							)}
						</FormControl>
					</Grid>
					<Grid
						sm={12}
						xs={12}>
						<FormControl fullWidth>
							<InputLabel
								required
								sx={{ color: "white" }}>
								Email address
							</InputLabel>
							<OutlinedInput
								type="email"
								size="small"
								sx={darkInputStyles}
								{...form.getFieldProps("email")}
								error={!!form.touched.email && !!form.errors.email}
							/>
							{!!form.touched.email && !!form.errors.email && (
								<FormHelperText error>{form.errors.email}</FormHelperText>
							)}
						</FormControl>
					</Grid>
					<Grid
						sm={12}
						xs={12}>
						<FormControl fullWidth>
							<InputLabel sx={{ color: "white" }}>Phone number</InputLabel>
							<OutlinedInput
								type="tel"
								size="small"
								sx={darkInputStyles}
								{...form.getFieldProps("phone")}
								error={!!form.touched.phone && !!form.errors.phone}
							/>
							{!!form.touched.phone && !!form.errors.phone && (
								<FormHelperText error>{form.errors.phone}</FormHelperText>
							)}
						</FormControl>
					</Grid>
					<Grid
						sm={12}
						xs={12}>
						<FormControl fullWidth>
							<InputLabel
								required
								sx={{ color: "white" }}>
								Franchise brand
							</InputLabel>
							<OutlinedInput
								size="small"
								sx={darkInputStyles}
								{...form.getFieldProps("company")}
								error={!!form.touched.company && !!form.errors.company}
							/>
							{!!form.touched.company && !!form.errors.company && (
								<FormHelperText error>{form.errors.company}</FormHelperText>
							)}
						</FormControl>
					</Grid>
					<Grid
						sm={12}
						xs={12}>
						<FormControl
							component="fieldset"
							fullWidth>
							<FormLabel
								component="legend"
								sx={{ color: "white" }}>
								Are you currently a registered franchise?
							</FormLabel>
							<RadioGroup {...form.getFieldProps("isRegistered")}>
								<FormControlLabel
									control={<Radio sx={{ color: "white" }} />}
									label="Yes"
									value="true"
								/>
								<FormControlLabel
									control={<Radio sx={{ color: "white" }} />}
									label="No"
									value="false"
								/>
							</RadioGroup>
						</FormControl>
					</Grid>
					<Grid
						sm={12}
						xs={12}>
						<FormControl fullWidth>
							<InputLabel sx={{ color: "white" }}>
								How many open locations?
							</InputLabel>
							<Select
								size="small"
								sx={darkInputStyles}
								{...form.getFieldProps("locations")}
								error={!!form.touched.locations && !!form.errors.locations}>
								<MenuItem value="0">0</MenuItem>
								<MenuItem value="1-10">1-10</MenuItem>
								<MenuItem value="11-30">11-50</MenuItem>
								<MenuItem value="31-50">&gt;50</MenuItem>
							</Select>
							{!!form.touched.locations && !!form.errors.locations && (
								<FormHelperText error>{form.errors.locations}</FormHelperText>
							)}
						</FormControl>
					</Grid>
					<Grid
						sm={12}
						xs={12}>
						<FormControl fullWidth>
							<InputLabel sx={{ color: "white" }}>
								Goals for number of franchises in the next year?
							</InputLabel>
							<OutlinedInput
								size="small"
								sx={darkInputStyles}
								{...form.getFieldProps("goalNumber")}
							/>
							{!!form.touched.goalNumber && !!form.errors.goalNumber && (
								<FormHelperText error>{form.errors.goalNumber}</FormHelperText>
							)}
						</FormControl>
					</Grid>
					<Grid
						sm={12}
						xs={12}>
						<FormControl fullWidth>
							<InputLabel sx={{ color: "white" }}>
								How did you hear about us?
							</InputLabel>
							<Select
								size="small"
								sx={darkInputStyles}
								{...form.getFieldProps("source")}
								error={!!form.touched.source && !!form.errors.source}>
								<MenuItem value="search">Search Engine (e.g., Google)</MenuItem>
								<MenuItem value="social">Social Media</MenuItem>
								<MenuItem value="referral">Referral</MenuItem>
								<MenuItem value="event">Event/Webinar</MenuItem>
								<MenuItem value="Other">Other</MenuItem>
							</Select>
							{!!form.touched.source && !!form.errors.source && (
								<FormHelperText error>{form.errors.source}</FormHelperText>
							)}
							{isDetailsRequired(form.values.source) && (
								<Box mt={2}>
									<FormControl fullWidth>
										<InputLabel sx={{ color: "white" }}>
											Please specify
										</InputLabel>
										<OutlinedInput
											size="small"
											sx={darkInputStyles}
											{...form.getFieldProps("sourceDetails")}
											error={
												!!form.touched.sourceDetails &&
												!!form.errors.sourceDetails
											}
										/>
										{!!form.touched.sourceDetails &&
											!!form.errors.sourceDetails && (
												<FormHelperText error>
													{form.errors.sourceDetails}
												</FormHelperText>
											)}
									</FormControl>
								</Box>
							)}
						</FormControl>
					</Grid>
					{/* @ts-ignore */}
					<ReCAPTCHA
						size="invisible"
						ref={recaptchaRef}
						sitekey={process.env.GOOGLE_RECAPTCHA_SITE_KEY!}
					/>
				</Grid>
				<Grid
					sm={12}
					xs={12}>
					<Stack
						direction="column"
						spacing={2}
						py={2}>
						<Button
							fullWidth
							size="large"
							variant="contained"
							type="submit">
							Let&apos;s talk
						</Button>
						<Typography
							color="text.secondary"
							variant="body2">
							By submitting your message, you agree to our{" "}
							<Link
								color="text.secondary"
								href="/privacy"
								underline="always"
								variant="subtitle2">
								privacy
							</Link>{" "}
							and{" "}
							<Link
								color="text.secondary"
								href="/security"
								underline="always"
								variant="subtitle2">
								data security policies.
							</Link>
							.
						</Typography>
					</Stack>
				</Grid>
			</Stack>
		</Box>
	);
}

const isDetailsRequired = (source: string) => {
	return ["referral", "event", "Other"].includes(source);
};
