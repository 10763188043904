import * as React from "react";
import Masonry from "@mui/lab/Masonry";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
	Cloud,
	Clock,
	Database,
	DeviceMobile,
	Drone,
	FilePlus,
	MonitorArrowUp,
	Globe,
	Lightning,
	Link,
	ListMagnifyingGlass,
	Lock,
	MagnifyingGlass,
	MapPin,
	MapPinArea,
	PaintBrush,
	Pencil,
	Rocket,
	Shapes,
	ShieldCheck,
	Stack as StackIcon,
	Tag,
	Timer,
	Translate,
	Users,
} from "@phosphor-icons/react";
import Button from "@mui/material/Button";

export function FeaturesSection(): React.JSX.Element {
	const features = [
		{
			title: "Powerful GIS Tools",
			description:
				"Analyze and visualize your data effortlessly with queries, spatial analysis, and stunning map styles.",
			label: "Map Styling & Analysis",
			icon: (
				<MapPinArea
					color="var(--mui-palette-primary-main)"
					size={24}
					weight="duotone"
				/>
			),
		},
		{
			title: "No Dataset Limits",
			description:
				"Upload and sync as many datasets as your storage allows. Need more? Expand with data packs.",
			label: "Unlimited Data",
			icon: (
				<Database
					color="var(--mui-palette-primary-main)"
					size={24}
					weight="duotone"
				/>
			),
		},
		{
			title: "More Layers, More Control",
			description: "Reuse datasets across multiple maps with no restrictions.",
			label: "Unlimited Layers",
			icon: (
				<StackIcon
					color="var(--mui-palette-primary-main)"
					size={24}
					weight="duotone"
				/>
			),
		},
		{
			title: "Advanced Symbology Builder",
			description:
				"Create and reuse thematic, category, heatmap, and custom graphic styles across your maps.",
			label: "Custom Styles",
			icon: (
				<PaintBrush
					color="var(--mui-palette-primary-main)"
					size={24}
					weight="duotone"
				/>
			),
		},
		{
			title: "Fully Branded Maps",
			description:
				"Use your domain, logo, and colors to create a seamless brand experience.",
			label: "Brand Identity",
			icon: (
				<Tag
					color="var(--mui-palette-primary-main)"
					size={24}
					weight="duotone"
				/>
			),
		},
		{
			title: "Localised Mapping",
			description: "Make your maps accessible in your language.",
			label: "Multi-Language Support",
			icon: (
				<Translate
					color="var(--mui-palette-primary-main)"
					size={24}
					weight="duotone"
				/>
			),
		},
		{
			title: "Vector Data Support",
			description:
				"Supports Esri™ Shapefile, Mapinfo® Tab, KML, CSV, FileGeodatabase, and GeoJSON.",
			label: "Vector Data Support",
			icon: (
				<FilePlus
					color="var(--mui-palette-primary-main)"
					size={24}
					weight="duotone"
				/>
			),
		},
		{
			title: "High-Quality Imagery",
			description: "Visualize drone photogrammetry or remote sensing data.",
			label: "Raster & WMS Support",
			icon: (
				<Drone
					color="var(--mui-palette-primary-main)"
					size={24}
					weight="duotone"
				/>
			),
		},
		{
			title: "Find Any Location",
			description: "Global street-level addresssearch.",
			label: "Address Lookup",
			icon: (
				<MapPin
					color="var(--mui-palette-primary-main)"
					size={24}
					weight="duotone"
				/>
			),
		},
		{
			title: "Find Insights Fast",
			description:
				"Easily search and filter attribute data to discover patterns.",
			label: "Data Search & Filtering",
			icon: (
				<MagnifyingGlass
					color="var(--mui-palette-primary-main)"
					size={24}
					weight="duotone"
				/>
			),
		},
		{
			title: "Ask Your Data Anything",
			description: "Run powerful queries to extract meaningful insights.",
			label: "Query Builder",
			icon: (
				<ListMagnifyingGlass
					color="var(--mui-palette-primary-main)"
					size={24}
					weight="duotone"
				/>
			),
		},
		{
			title: "Spatial Analysis Made Easy",
			description:
				"Perform proximity, buffer, and intersection analyses with ease.",
			label: "Spatial Analysis",
			icon: (
				<Globe
					color="var(--mui-palette-primary-main)"
					size={24}
					weight="duotone"
				/>
			),
		},
		{
			title: "Multi-User Editing",
			description:
				"Collaborate with your team in real time with role-based permissions.",
			label: "Collaboration & Sharing",
			icon: (
				<Users
					color="var(--mui-palette-primary-main)"
					size={24}
					weight="duotone"
				/>
			),
		},
		{
			title: "Shareable Links",
			description: "Easily share maps with public or private access controls.",
			label: "Collaboration & Sharing",
			icon: (
				<Link
					color="var(--mui-palette-primary-main)"
					size={24}
					weight="duotone"
				/>
			),
		},
		{
			title: "Fast Rendering",
			description:
				"Optimized for large datasets and high-performance visualizations.",
			label: "Performance & Optimization",
			icon: (
				<Lightning
					color="var(--mui-palette-primary-main)"
					size={24}
					weight="duotone"
				/>
			),
		},
		{
			title: "Caching & Tile Optimization",
			description: "Speed up load times with smart caching and tile rendering.",
			label: "Performance & Optimization",
			icon: (
				<Timer
					color="var(--mui-palette-primary-main)"
					size={24}
					weight="duotone"
				/>
			),
		},
		{
			title: "Mobile-Friendly Maps",
			description: "Responsive and optimized for seamless mobile experiences.",
			label: "Performance & Optimization",
			icon: (
				<DeviceMobile
					color="var(--mui-palette-primary-main)"
					size={24}
					weight="duotone"
				/>
			),
		},
		{
			title: "Role-Based Permissions",
			description:
				"Control who can view, edit, or publish maps with granular permissions.",
			label: "Security & Access Control",
			icon: (
				<ShieldCheck
					color="var(--mui-palette-primary-main)"
					size={24}
					weight="duotone"
				/>
			),
		},
		{
			title: "Access Restrictions",
			description:
				"Secure maps with password protection or user authentication.",
			label: "Security & Access Control",
			icon: (
				<Lock
					color="var(--mui-palette-primary-main)"
					size={24}
					weight="duotone"
				/>
			),
		},
		{
			title: "WMS",
			description: "Integrate layers from third-party WMS providers.",
			label: "External layers",
			icon: (
				<Cloud
					color="var(--mui-palette-primary-main)"
					size={24}
					weight="duotone"
				/>
			),
		},
		{
			title: "Custom Icons & Markers",
			description: "Upload and use personalized icons for better map clarity.",
			label: "Customization & Theming",
			icon: (
				<Shapes
					color="var(--mui-palette-primary-main)"
					size={24}
					weight="duotone"
				/>
			),
		},
		{
			title: "Annotate Maps",
			description:
				"Add labels, drawings, and custom notes to highlight key areas.",
			label: "Customization & Theming",
			icon: (
				<Pencil
					color="var(--mui-palette-primary-main)"
					size={24}
					weight="duotone"
				/>
			),
		},
		{
			title: "Massive Data Support",
			description: "Upload and map massive vector and raster data.",
			label: "Customization & Theming",
			icon: (
				<MonitorArrowUp
					color="var(--mui-palette-primary-main)"
					size={24}
					weight="duotone"
				/>
			),
			comingSoon: true,
		},
		{
			title: "Endless Possibilities",
			description: "Packed with features to power your mapping projects.",
			label: "And More!",
			icon: (
				<Rocket
					color="var(--mui-palette-primary-main)"
					size={24}
					weight="duotone"
				/>
			),
		},
	];

	const [showAll, setShowAll] = React.useState(false);
	return (
		<Box
			sx={{
				bgcolor: "var(--mui-palette-background-level1)",
				py: { xs: "60px", sm: "120px" },
			}}>
			<Container maxWidth="xl">
				<Typography
					sx={{ textAlign: "center", mb: 4 }}
					variant="h3">
					Key Features
				</Typography>
				<Box
					sx={{
						position: "relative",
						overflow: "hidden",
						maxHeight: showAll ? "none" : 300,
					}}>
					<Masonry
						columns={{ xs: 1, sm: 3, md: 4, xl: 5 }}
						defaultColumns={5}
						defaultHeight={200}
						defaultSpacing={2}
						sequential
						spacing={{ xs: 2, sm: 3, md: 4, xl: 5 }}>
						{features.map((feature) => (
							<Card
								key={feature.title}
								raised
								sx={{ cursor: "pointer" }}>
								<CardActionArea>
									<CardContent>
										<Stack spacing={1}>
											<Box
												sx={{
													display: "flex",
													alignItems: "center",
													justifyContent: "space-between",
												}}>
												{feature.icon}
												{feature.comingSoon ? (
													<Chip
														color="success"
														icon={<Clock />}
														label="COMING SOON"
														size="small"
														sx={{ fontSize: 9, fontWeight: 600 }}
														variant="soft"
													/>
												) : null}
											</Box>
											<Typography variant="subtitle2">
												{feature.title}
											</Typography>
											<Typography
												color="text.secondary"
												variant="caption">
												{feature.description}
											</Typography>
										</Stack>
									</CardContent>
								</CardActionArea>
							</Card>
						))}
					</Masonry>
					{!showAll && (
						<Box
							sx={{
								position: "absolute",
								bottom: 0,
								left: 0,
								width: "100%",
								height: 80,
								background:
									"linear-gradient(to bottom, transparent, var(--mui-palette-background-level1))",
								pointerEvents: "none",
							}}
						/>
					)}
				</Box>
			</Container>
			<Stack sx={{ mt: 2, alignItems: "center" }}>
				<Button
					color="secondary"
					onClick={() => {
						setShowAll(!showAll);
					}}
					size="small"
					variant="contained">
					{showAll ? "Show less" : "Show more"}
				</Button>
			</Stack>
		</Box>
	);
}
