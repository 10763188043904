import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import {
	ArrowRight,
	ChartBar,
	FileText,
	Globe,
	Handshake,
	LightbulbFilament,
	PaperPlaneTilt,
} from "@phosphor-icons/react";

interface UseCaseCard {
	id: string;
	eyebrow: string;
	icon: React.ReactElement;
	title: string;
	description: string;
	image: string;
	link: string;
}

export function UseCases(): React.JSX.Element {
	const [hoveredId, setHoveredId] = React.useState<string | null>(null);
	const useCases: {
		heading: string;
		subHeading: string;
		cards: UseCaseCard[];
	} = {
		heading: "Mapping for every GIS Pro",
		subHeading:
			"Whether you're plotting field data, wowing clients with spatial insights, or engaging the public with interactive maps, Mango transforms your everyday GIS challenges into opportunities for success.",
		cards: [
			{
				id: "use-case-1",
				eyebrow: "Publish",
				icon: <PaperPlaneTilt />,
				title: "For fieldwork and surveys",
				description:
					"Transform field data into interactive maps quickly, sharing real-time insights with teams or clients anywhere, on any device.",
				image: "/new-mangomap-assets/map-survey.png",
				link: "https://mangomap.com/engineering/maps/105825/geotechnical-survey-of-dauphin-island-al",
			},
			{
				id: "use-case-2",
				eyebrow: "Connect",
				icon: <Globe />,
				title: "For public engagement",
				description:
					"Craft user-friendly maps that communities and stakeholders can explore, boosting project visibility with zero added cost or effort.",
				image: "/new-mangomap-assets/map-wind-farm.png",
				link: "https://mangomap.com/engineering/maps/74202/north-sea-wind-farms",
			},
			{
				id: "use-case-3",
				eyebrow: "Analyse",
				icon: <ChartBar />,
				title: "For spatial analysis",
				description:
					"Crunch complex datasets into clear, interactive visuals fast, uncovering insights that drive smarter decisions for any project.",
				image: "/new-mangomap-assets/map-parcel-query.png",
				link: "https://mangomap.com/orange-county/maps/87210/parcel-query-advanced",
			},
			{
				id: "use-case-4",
				eyebrow: "Report",
				icon: <FileText />,
				title: "For dynamic reporting",
				description:
					"Turn raw GIS data into live, shareable reports, keeping clients and teams updated without the grind of static documents.",
				image: "/new-mangomap-assets/map-reporting.png",
				link: "https://mangomap.com/engineering/maps/89682/expressway-truck-stop-site-map",
			},
			{
				id: "use-case-5",
				eyebrow: "Collaborate",
				icon: <Handshake />,
				title: "For client presentations",
				description:
					"Present spatial models or risk maps with flair, sparking instant understanding and sealing the deal with stakeholders.",
				image: "/new-mangomap-assets/map-presentation.png",
				link: "https://mangomap.com/environment/maps/54471/great-barrier-reef-marine-park-coral-bleaching",
			},
			{
				id: "use-case-6",
				eyebrow: "Innovate",
				icon: <LightbulbFilament />,
				title: "For rapid prototyping",
				description:
					"Create secure, editable map drafts in a snap, testing concepts safely before unveiling them to clients or the world.",
				image: "/new-mangomap-assets/map-assets.png",
				link: "https://mangomap.com/engineering/maps/74161/asset-management-road-signs",
			},
		],
	};

	return (
		<div>
			<Container
				maxWidth="lg"
				sx={{ py: "120px" }}>
				<Stack spacing={8}>
					<Stack
						maxWidth="700px"
						spacing={2}
						sx={{ mx: "auto" }}>
						<Typography
							sx={{ textAlign: "center" }}
							variant="h3">
							{useCases.heading}
						</Typography>
						<Typography
							color="text.secondary"
							sx={{ textAlign: "center" }}>
							{useCases.subHeading}
						</Typography>
					</Stack>
					<Grid
						container
						maxWidth="xl"
						spacing={6}>
						{useCases.cards.map((card: UseCaseCard) => (
							<Grid
								key={card.id}
								md={4}
								sm={6}
								xs={12}>
								<Card
									elevation={hoveredId === card.id ? 14 : 2}
									onMouseEnter={() => {
										setHoveredId(card.id);
									}}
									onMouseLeave={() => {
										setHoveredId(null);
									}}
									sx={{
										display: "flex",
										flexDirection: "column",
										height: "100%",
										alignItems: "stretch",
										transition: "transform 0.3s, box-shadow 0.3s",
									}}>
									<Box
										sx={{
											height: "200px",
											overflow: "hidden",
											position: "relative",
										}}>
										<CardMedia
											alt={card.description}
											component="img"
											image={card.image}
											sx={{
												height: "100%",
												width: "100%",
												objectFit: "cover",
												transition: "transform 0.6s ease",
												transform:
													hoveredId === card.id ? "scale(1.5)" : "scale(1.2)",
											}}
										/>
									</Box>
									<CardContent sx={{ flexGrow: 1 }}>
										<Stack spacing={1}>
											<div>
												<Chip
													color="success"
													icon={card.icon}
													label={card.eyebrow}
													size="small"
													variant="soft"
												/>
											</div>
											<Typography
												component="div"
												variant="h5">
												{card.title}
											</Typography>
											<Typography
												sx={{ color: "text.secondary" }}
												variant="body2">
												{card.description}
											</Typography>
										</Stack>
									</CardContent>
									<CardActions>
										<Button
											color="primary"
											endIcon={
												<Box
													sx={{
														display: "inline-flex",
														transition: "transform 0.3s, opacity 0.3s",
														transform:
															hoveredId === card.id
																? "translateX(0)"
																: "translateX(-10px)",
														opacity: hoveredId === card.id ? 1 : 0,
													}}>
													<ArrowRight size={16} />
												</Box>
											}
											href={card.link}
											size="large"
											target="_blank"
											variant="text">
											View example
										</Button>
									</CardActions>
								</Card>
							</Grid>
						))}
					</Grid>
				</Stack>
			</Container>
		</div>
	);
}
