import * as React from "react";
import RouterLink from "next/link";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { StackSimple } from "@phosphor-icons/react";
import { signUpV1Or2 } from "config/paths";

export function Hero(): React.JSX.Element {
	const hero = {
		eyebrow: "Online GIS Mapping",
		icon: <StackSimple />,
		heading: "GIS maps, made simple.",
		subHeading:
			"The accessible and user-friendly alternative to Esri for smart spenders.",
		ctaButton: "Start your 30-day free trial",
		keyObjection: "No obligation, no credit card required",
		socialProof:
			'"Mango delivers all the functionality we need to grow." - Phil Henry, Managing Director, Business Geographics',
		image: "/new-mangomap-assets/map-hero2.jpg",
	};

	const customers = [
		{
			customer: "Sensar",
			src: "/new-mangomap-assets/customer-sensar.png",
		},
		{
			customer: "GIS4AG",
			src: "/new-mangomap-assets/customer-gis4ag.svg",
		},
		{
			customer: "Geotech Info",
			src: "/new-mangomap-assets/customer-geotech-info.png",
		},
		{
			customer: "Enerko",
			src: "/new-mangomap-assets/customer-enerko.svg",
		},
		{
			customer: "Swift Aerial Surveys",
			src: "/new-mangomap-assets/customer-swift.svg",
		},
		{
			customer: "GBI Experts",
			src: "/new-mangomap-assets/customer-gbi.svg",
		},
		{
			customer: "Plumas County",
			src: "/new-mangomap-assets/customer-plumas.png",
		},
	];

	const avatars = [
		{ alt: "User 5", src: "/new-mangomap-assets/avatar-5.png" },
		{ alt: "User 1", src: "/new-mangomap-assets/avatar-1.png" },
	];

	return (
		<Box
			sx={{
				bgcolor: "var(--mui-palette-neutral-50)",
				color: "var(--mui-palette-common-white)",
				overflow: "hidden",
				position: "relative",
			}}>
			<Container
				maxWidth="md"
				sx={{ position: "relative", py: "120px", zIndex: 3 }}>
				<Stack spacing={6}>
					<Stack
						spacing={2}
						sx={{
							justifyContent: "center",
							alignItems: "center",
						}}>
						<div>
							<Chip
								color="secondary"
								icon={hero.icon}
								label={hero.eyebrow}
								size="small"
								variant="soft"
							/>
						</div>
						<Typography
							align="center"
							color="var(--mui-palette-common-black)"
							sx={{ fontSize: "4rem", fontWeight: 700, lineHeight: 1.2 }}
							variant="h1">
							{hero.heading}
						</Typography>
						<Typography
							color="text.secondary"
							sx={{ fontSize: "1.2rem", fontWeight: 500, textAlign: "center" }}
							variant="h3">
							{hero.subHeading}
						</Typography>
					</Stack>
					<Stack
						direction="column"
						spacing={1}
						sx={{ justifyContent: "center", alignItems: "center" }}>
						<div>
							<Button
								component={RouterLink}
								href={signUpV1Or2}
								size="large"
								variant="contained">
								{hero.ctaButton}
							</Button>
						</div>
						<Typography
							color="neutral.400"
							variant="overline">
							{hero.keyObjection}
						</Typography>
					</Stack>
					<Stack
						direction="row"
						spacing={1}
						sx={{ alignItems: "center", justifyContent: "center " }}>
						<AvatarGroup
							sx={{
								"& .MuiAvatar-root": {
									border: "2px solid var(--mui-palette-neutral-950)",
								},
							}}>
							{avatars.map((avatar) => (
								<Avatar
									alt={avatar.alt}
									key={avatar.alt}
									src={avatar.src}
								/>
							))}
						</AvatarGroup>
						<Typography
							color="neutral.400"
							sx={{ whiteSpace: "wrap" }}
							variant="caption">
							{hero.socialProof}
						</Typography>
					</Stack>
				</Stack>
			</Container>
			<Container
				maxWidth="lg"
				sx={{ position: "relative", zIndex: 2 }}>
				<Box
					component="img"
					src={hero.image}
					sx={{
						display: "block",
						height: "auto",
						borderRadius: "20px",
						position: "relative",
						width: "100%",
						zIndex: 2,
					}}
				/>
			</Container>
			<Container
				maxWidth="xl"
				sx={{
					py: "64px",
					borderBottom: "1px solid var(--mui-palette-divider)",
				}}>
				<Stack spacing={3}>
					<Stack
						direction="row"
						spacing={6}
						sx={{
							alignItems: "center",
							color: "var(--mui-palette-neutral-500)",
							flexWrap: "wrap",
							justifyContent: "center",
							filter: "grayscale(1) opacity(0.7)",
						}}>
						{customers.map((customer) => (
							<Box
								alt={customer.customer}
								component="img"
								key={customer.customer}
								src={customer.src}
								sx={{ height: "40px" }}
							/>
						))}
					</Stack>
				</Stack>
			</Container>
		</Box>
	);
}
