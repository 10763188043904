import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { Bank } from "@phosphor-icons/react";

import { Plan } from "./plan";

const plans = [
	{
		id: "business",
		name: "Business",
		popular: false,
		priceAnnual: 79,
		priceMonthly: 99,
		currency: "USD",
		description:
			"For individuals and SMEs that need easy map creation and distribution.",
		features: [
			"20 Maps",
			"Map vector, raster, and WMS",
			"Share maps publicly",
			"Share maps and data privately",
			"All core mapping features",
		],
	},
	{
		id: "enterprise",
		name: "Enterprise",
		popular: true,
		priceAnnual: 159,
		priceMonthly: 199,
		action: <Button variant="contained">Start free trial</Button>,
		currency: "USD",
		description:
			"For consultants and businesses that need control, collaboration, and automation.",
		features: [
			"40 Maps",
			"Unlimited viewer seats",
			"Securely share maps with selected viewers",
			"Automate data updates",
			"Dedicated account manager",
			"Priority support",
			"+ Everything in Business",
		],
	},
	{
		id: "agency",
		name: "Agency",
		popular: false,
		priceAnnual: 319,
		priceMonthly: 399,
		currency: "USD",
		description:
			"For organisations that need total reliability and unlimited expandability.",
		features: [
			"100 Maps",
			"Dedicated account manager",
			"Priority support",
			"+ Everything in Enterprise",
		],
	},
];

export function PlansTable() {
	const [isAnnual, setIsAnnual] = React.useState(true);

	const handleToggle = () => {
		setIsAnnual((prev) => !prev);
	};

	return (
		<Box
			sx={{
				bgcolor: "var(--mui-palette-background-level1)",
				py: { xs: "60px", sm: "120px" },
			}}>
			<Container maxWidth="lg">
				<Stack
					marginBottom={12}
					spacing={3}>
					<Stack
						spacing={2}
						sx={{ alignItems: "center" }}>
						<Typography
							sx={{ textAlign: "center" }}
							variant="h3">
							Sensibly priced web maps, no claptrap.
						</Typography>
						<Typography
							color="text.secondary"
							sx={{ textAlign: "center" }}
							variant="body1">
							Mango is perfect for mapping projects of any size and length.
						</Typography>
						<Stack
							direction="row"
							spacing={2}
							sx={{ alignItems: "center" }}>
							<Switch
								checked={isAnnual}
								onChange={handleToggle}
							/>
							<Typography variant="body1">Billed annually</Typography>
							<Chip
								color="success"
								label="20% OFF"
								size="small"
							/>
						</Stack>
					</Stack>
					<div>
						<Grid
							container
							spacing={3}>
							{plans.map((plan) => (
								<Grid
									key={plan.id}
									md={4}
									xs={12}>
									<Plan
										{...plan}
										action={plan.action}
										isAnnual={isAnnual}
										price={isAnnual ? plan.priceAnnual : plan.priceMonthly}
									/>
								</Grid>
							))}
						</Grid>
					</div>
				</Stack>
			</Container>
			<Container maxWidth="md">
				<Stack>
					<Card sx={{ p: 2 }}>
						<CardContent sx={{ p: 2 }}>
							<Stack spacing={3}>
								<Typography
									align="center"
									variant="h5">
									Flexible Procurement and Payment
								</Typography>
								<Stack
									direction={{ sx: "column", md: "row" }}
									spacing={3}
									useFlexGap>
									<Box
										sx={{
											py: { xs: 2, md: 0 },
										}}
										width={{ xs: "100%", md: "50%" }}>
										<Stack
											direction="row"
											spacing={2}
											sx={{
												display: "flex",
												alignItems: "center",
												justifyContent: "flex-start",
												marginBottom: 2,
											}}>
											<Box
												component="img"
												height="42px"
												src="/new-mangomap-assets/payment-amex.svg"
											/>
											<Box
												component="img"
												src="/new-mangomap-assets/payment-visa.svg"
												width="56px"
											/>
											<Box
												component="img"
												height="36px"
												src="/new-mangomap-assets/payment-mastercard.svg"
											/>
										</Stack>
										<Typography
											color="text.secondary"
											component="p">
											We accept all major cards for self-serve checkout.
										</Typography>
									</Box>
									<Divider
										flexItem
										orientation="vertical"
										variant="middle"
									/>
									<Box
										sx={{
											py: { xs: 2, md: 0 },
										}}
										width={{ xs: "100%", md: "50%" }}>
										<Box width="48px">
											<Bank
												size="large"
												weight="duotone"
											/>
										</Box>
										<Typography
											color="text.secondary"
											component="p">
											Need a long-term solution? Get 30% off multi-year
											subscriptions with invoiced billing.
										</Typography>
									</Box>
								</Stack>
							</Stack>
						</CardContent>
						<CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
							<Button
								color="secondary"
								href="/contact"
								size="small"
								variant="contained">
								Talk to sales
							</Button>
						</CardActions>
					</Card>
				</Stack>
			</Container>
		</Box>
	);
}
