import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { signUpV1Or2 } from "config/paths";
import * as React from "react";

export function StartBuilding(): React.JSX.Element {
	const cta = {
		heading: "Simplify mapping and slash costs today",
		subHeading:
			"Mango gives you fast, affordable, and reliable mapping that wows clients and teams alike. With over 250,000 maps created and a decade of trust from GIS pros worldwide, we're here to make your work easier and more profitable. Jump in now—your next project deserves it.",
		ctaButton: "Start your 30 day free trial",
		ctaSubText:
			"Wrestling with overpriced software, chasing deadlines, and trying to keep everyone happy? Mango was built to cut through that noise, giving you a straightforward, cost-effective way to shine.",
		image: "/new-mangomap-assets/home-howitworks.jpg",
	};

	return (
		<Box sx={{ bgcolor: "var(--mui-palette-background-level1)", pb: "60px" }}>
			<Container>
				<Box
					sx={{
						borderRadius: "20px",
						border: "1px solid transparent",
						color: "var(--mui-palette-common-white)",
						display: "flex",
						flexDirection: { xs: "column", md: "row" },
						overflow: "hidden",
						position: "relative",
					}}>
					<Box
						sx={{
							bgcolor: "var(--mui-palette-neutral-950)",
							borderRadius: "20px",
							bottom: "1px",
							left: "-1px",
							position: "absolute",
							right: 0,
							top: "-1px",
							zIndex: 0,
						}}>
						<Box
							sx={{
								alignItems: "center",
								bottom: 0,
								display: "flex",
								justifyContent: "center",
								left: 0,
								position: "absolute",
								right: 0,
								top: 0,
								zIndex: 1,
							}}>
							<Box
								component="img"
								src="/new-mangomap-assets/home-rectangles.svg"
								sx={{ height: "auto", width: "1900px" }}
							/>
						</Box>
					</Box>
					<Stack
						spacing={3}
						sx={{
							flex: "0 1 auto",
							position: "relative",
							px: "64px",
							py: "64px",
							width: { xs: "100%", md: "45%" },
							zIndex: 1,
						}}>
						<Stack spacing={2}>
							<Typography
								color="inherit"
								variant="h3">
								{cta.heading}
							</Typography>
							<Typography color="neutral.300">{cta.subHeading}</Typography>
						</Stack>
						<div>
							<Button
								href={signUpV1Or2}
								size="large"
								target="_blank"
								variant="contained">
								{cta.ctaButton}
							</Button>
						</div>
						<div>
							<Typography
								color="neutral.300"
								variant="caption">
								{cta.ctaSubText}
							</Typography>
						</div>
					</Stack>
					<Box
						sx={{
							alignItems: { xs: "flex-end", md: "stretch" },
							borderRadius: "20px",
							display: "flex",
							flex: "1 1 auto",
							flexDirection: "column",
							justifyContent: "flex-end",
							pl: { xs: 0, md: 0 }, // Changed from pl: { xs: '64px', md: 0 }
							position: "relative",
							zIndex: 2,
						}}>
						<Box
							sx={{
								height: { xs: "300px", md: "340px" },
								position: "relative",
								width: "100%", // Changed from width: { xs: '80%', md: '100%' }
							}}>
							<Box
								sx={{
									height: "100%",
									position: "relative",
									width: "100%",
									zIndex: 1,
								}}>
								<Box
									component="img"
									src={cta.image}
									sx={{
										display: "block",
										bottom: "1px",
										left: 0,
										position: "absolute",
										width: "100%",
										height: "auto",
										objectFit: "cover",
										borderRadius: { xs: "0", md: "40px 0 0 0" }, // Updated borderRadius for xs screens
									}}
								/>
							</Box>
						</Box>
					</Box>
				</Box>
			</Container>
		</Box>
	);
}
