import * as React from "react";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";

export function About(): React.JSX.Element {
	return (
		<Container
			maxWidth="md"
			sx={{ py: 20 }}>
			<Card
				raised
				sx={{ p: 8 }}>
				<CardContent>
					<Stack spacing={2}>
						<Typography
							color="text.primary"
							component="h1"
							variant="h3">
							About Mangomap
						</Typography>
						<Typography
							color="text.primary"
							component="h2"
							variant="subtitle1">
							Mango makes creating engaging, data-driven web maps effortless –
							no coding, no servers, no complications.
						</Typography>
						<Typography>
							Since 2013, our platform has empowered professionals to uncover
							valuable insights and tell compelling stories through interactive
							maps.
						</Typography>
						<Typography>
							Our global community spans over 115 countries, with more than
							200,000 maps created across diverse industries from local
							governments to franchising and engineering to business
							intelligence. At the heart of Mango is our dedicated team of
							experts, committed to delivering a seamless mapping experience
							that drives real results for your organization.
						</Typography>
						<Typography>
							Supporting our clients is our team of experts, who work tirelessly
							to ensure that Mango is always simple for organizations, their
							users and their bottom line.
						</Typography>
						<Typography>
							Headquartered in Bristol 🇬🇧, Mango continues to transform how
							organizations visualize and share data. Interested in learning
							more? <Link href="/contact">Get in touch today</Link>.
						</Typography>
					</Stack>
				</CardContent>
			</Card>
		</Container>
	);
}
