import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
	CaretDown,
	List,
	MapPinSimpleArea,
	MapTrifold,
} from "@phosphor-icons/react";
import { paths, signInV1Or2, signUpV1Or2 } from "config/paths";
import RouterLink from "next/link";
import { useRouter } from "next/router";
import * as React from "react";

import { Dropdown } from "~ui-components/components/atoms/dropdown/dropdown";
import { DropdownPopover } from "~ui-components/components/atoms/dropdown/dropdown-popover";
import { DropdownTrigger } from "~ui-components/components/atoms/dropdown/dropdown-trigger";
import { MangoLogo } from "~ui-components/components/atoms/mango-logo";
import { isNavItemActive } from "~ui-components/libs/is-nav-item-active";
import type { NavItemConfig } from "~ui-components/types/nav";

import { MobileNav } from "./mobile-nav";

function SolutionsPopover(): React.JSX.Element {
	return (
		<Box sx={{ p: 2, width: "300px" }}>
			<Stack spacing={2}>
				{/* GIS Link */}
				<Box
					component={RouterLink}
					href="/"
					sx={{
						borderRadius: 1,
						display: "flex",
						p: 1,
						textDecoration: "none",
						"&:hover": {
							bgcolor: "var(--mui-palette-neutral-50)",
						},
					}}>
					<Box
						sx={{
							width: "24px",
							display: "flex",
							alignItems: "flex-start",
							justifyContent: "center",
							flexShrink: 0,
							mr: 1,
						}}>
						<MapTrifold
							color="var(--mui-palette-primary-500)"
							size={24}
						/>
					</Box>
					<Box sx={{ flex: "1 1 auto" }}>
						<Typography
							color="text.primary"
							variant="subtitle2">
							Mango for GIS
						</Typography>
						<Typography
							color="text.secondary"
							sx={{ mt: 1 }}
							variant="body2">
							Self-serve professional GIS mapping made simple.
						</Typography>
					</Box>
				</Box>

				{/* Franchise Link */}
				<Box
					component={RouterLink}
					href="/franchise"
					sx={{
						borderRadius: 1,
						display: "flex",
						p: 1,
						textDecoration: "none",
						"&:hover": {
							bgcolor: "var(--mui-palette-neutral-50)",
						},
					}}>
					<Box
						sx={{
							width: "24px",
							display: "flex",
							alignItems: "flex-start",
							justifyContent: "center",
							flexShrink: 0,
							mr: 1,
						}}>
						<MapPinSimpleArea
							color="var(--mui-palette-primary-500)"
							size={24}
						/>
					</Box>
					<Box sx={{ flex: "1 1 auto" }}>
						<Typography
							color="text.primary"
							variant="subtitle2">
							Mango for Franchise
						</Typography>
						<Typography
							color="text.secondary"
							sx={{ mt: 1 }}
							variant="body2">
							Expert territory mapping tailored for franchise expansion.
						</Typography>
					</Box>
				</Box>
			</Stack>
		</Box>
	);
}

// Now update the MainNav component to use SolutionsPopover
export function MainNav(): React.JSX.Element {
	const [openNav, setOpenNav] = React.useState<boolean>(false);
	const { pathname } = useRouter();

	return (
		<React.Fragment>
			<Box
				component="header"
				sx={{
					bgcolor: "rgba(255, 255, 255, 0.7)",
					backdropFilter: "blur(12px)",
					color: "var(--mui-palette-common-black)",
					left: 0,
					position: "sticky",
					right: 0,
					top: 0,
					zIndex: "var(--MainNav-zIndex)",
				}}>
				<Container
					maxWidth="lg"
					sx={{
						display: "flex",
						minHeight: "var(--MainNav-height)",
						py: "16px",
					}}>
					<Stack
						direction="row"
						spacing={2}
						sx={{ alignItems: "center", flex: "1 1 auto" }}>
						<Box
							component={RouterLink}
							href={paths.home}
							sx={{ display: "inline-flex" }}>
							<MangoLogo
								height={32}
								width={122}
							/>
						</Box>

						<Box
							component="nav"
							sx={{ display: { xs: "none", md: "block" } }}>
							<Stack
								direction="row"
								spacing={1}
								sx={{
									listStyle: "none",
									m: 0,
									p: 0,
									pl: 1,
									color: "var(--mui-palette-common-black)",
								}}>
								<NavItem
									pathname={pathname}
									title="Solutions">
									<SolutionsPopover />
								</NavItem>
								<NavItem
									external
									href="https://help.mangomap.com"
									pathname=""
									title="Documentation"
								/>
								<NavItem
									href="/pricing"
									pathname=""
									title="Pricing"
								/>
							</Stack>
						</Box>
					</Stack>
					<Stack
						direction="row"
						spacing={2}
						sx={{
							alignItems: "center",
							flex: "1 1 auto",
							justifyContent: "flex-end",
						}}>
						<Button
							component="a"
							href={signInV1Or2}
							sx={{ display: { xs: "none", md: "flex" } }}
							variant="outlined">
							Sign in
						</Button>
						<Button
							component="a"
							href={signUpV1Or2}
							sx={{ display: { xs: "none", md: "flex" } }}
							variant="contained">
							Sign up
						</Button>
						<IconButton
							onClick={() => {
								setOpenNav(true);
							}}
							sx={{
								color: "var(--mui-palette-common-black)",
								display: { xs: "flex", md: "none" },
							}}>
							<List />
						</IconButton>
					</Stack>
				</Container>
			</Box>
			<MobileNav
				onClose={() => {
					setOpenNav(false);
				}}
				open={openNav}
			/>
		</React.Fragment>
	);
}

interface NavItemProps extends Omit<NavItemConfig, "key" | "items"> {
	children?: React.ReactNode;
	pathname: string;
}

export function NavItem({
	children,
	disabled,
	external,
	href,
	matcher,
	pathname,
	title,
}: NavItemProps): React.JSX.Element {
	const active = isNavItemActive({
		disabled,
		external,
		href,
		matcher,
		pathname,
	});
	const hasPopover = Boolean(children);

	const element = (
		<Box
			component="li"
			sx={{ userSelect: "none" }}>
			<Box
				{...(hasPopover
					? {
							onClick: (event: React.MouseEvent<HTMLElement>): void => {
								event.preventDefault();
							},
							role: "button",
						}
					: {
							...(href
								? {
										component: external ? "a" : RouterLink,
										href,
										target: external ? "_blank" : undefined,
										rel: external ? "noreferrer" : undefined,
									}
								: { role: "button" }),
						})}
				sx={{
					alignItems: "center",
					borderRadius: 1,
					color: "var(--mui-palette-neutral-500)",
					cursor: "pointer",
					display: "flex",
					flex: "0 0 auto",
					gap: 1,
					p: "6px 16px",
					textAlign: "left",
					textDecoration: "none",
					whiteSpace: "nowrap",
					...(disabled && {
						bgcolor: "var(--mui-palette-action-disabledBackground)",
						color: "var(--mui-action-disabled)",
						cursor: "not-allowed",
					}),
					...(active && { color: "var(--mui-palette-neutral-700)" }),
					"&:hover": {
						...(!disabled &&
							!active && {
								bgcolor: "var(--mui-palette-neutral-100)",
								color: "var(--mui-palette-neutral-700)",
							}),
					},
				}}
				tabIndex={0}>
				<Box
					component="span"
					sx={{ flex: "1 1 auto" }}>
					<Typography
						component="span"
						sx={{
							color: "inherit",
							fontSize: "0.875rem",
							fontWeight: 500,
							lineHeight: "28px",
						}}>
						{title}
					</Typography>
				</Box>
				{hasPopover ? (
					<Box
						sx={{
							alignItems: "center",
							color: "inherit",
							display: "flex",
							flex: "0 0 auto",
						}}>
						<CaretDown fontSize="var(--icon-fontSize-sm)" />
					</Box>
				) : null}
			</Box>
		</Box>
	);

	if (hasPopover) {
		return (
			<Dropdown>
				<DropdownTrigger>{element}</DropdownTrigger>
				<DropdownPopover
					PaperProps={{
						sx: {
							width: "auto",
							maxWidth: "100%",
						},
					}}
					anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
					transformOrigin={{ horizontal: "center", vertical: "top" }}>
					{children}
				</DropdownPopover>
			</Dropdown>
		);
	}

	return element;
}
