import * as React from "react";
import RouterLink from "next/link";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { UsersThree } from "@phosphor-icons/react";
import { signUpV1Or2 } from "config/paths";

export function Hero(): React.JSX.Element {
	return (
		<Box
			sx={{
				bgcolor: "var(--mui-palette-neutral-50)",
				color: "var(--mui-palette-common-white)",
				overflow: "hidden",
				position: "relative",
			}}>
			<Box
				sx={{
					alignItems: "center",
					bottom: 0,
					display: "flex",
					justifyContent: "center",
					left: 0,
					position: "absolute",
					right: 0,
					top: 0,
					zIndex: 0,
				}}>
				<Box
					component="img"
					src="/new-mangomap-assets/home-cosmic.svg"
					sx={{ height: "auto", width: "1600px" }}
				/>
			</Box>
			<Box
				sx={{
					alignItems: "center",
					bottom: 0,
					display: "flex",
					justifyContent: "center",
					left: 0,
					position: "absolute",
					right: 0,
					top: 0,
					zIndex: 1,
				}}>
				<Box
					component="img"
					src="/new-mangomap-assets/home-rectangles.svg"
					sx={{ height: "auto", width: "1900px" }}
				/>
			</Box>
			<Container
				maxWidth="md"
				sx={{ position: "relative", py: "120px", zIndex: 3 }}>
				<Stack spacing={6}>
					<Stack
						spacing={2}
						sx={{
							justifyContent: "center",
							alignItems: "center",
						}}>
						<div>
							<Chip
								color="success"
								icon={<UsersThree />}
								label="LocGov"
								size="small"
								variant="outlined"
							/>
						</div>
						<Typography
							align="center"
							color="var(--mui-palette-common-black)"
							sx={{ fontSize: "3.5rem", fontWeight: 700, lineHeight: 1.2 }}
							variant="h1">
							Reliable County Maps Without the Cost or Complexity
						</Typography>
						<Typography
							color="text.secondary"
							sx={{ fontSize: "1.5rem", fontWeight: 400, textAlign: "center" }}
							variant="h3">
							A simple, affordable GIS platform for rural pros to publish
							trusted maps that keep communities and committees happy.
						</Typography>
					</Stack>
					<Stack
						direction="column"
						spacing={1}
						sx={{ justifyContent: "center", alignItems: "center" }}>
						<div>
							<Button
								component={RouterLink}
								href={signUpV1Or2}
								size="large"
								variant="contained">
								Start Your Free Trial
							</Button>
						</div>
						<Typography
							color="neutral.400"
							variant="overline">
							No credit card required – just results you can count on.
						</Typography>
					</Stack>
					<Stack
						direction="row"
						spacing={1}
						sx={{ alignItems: "center", justifyContent: "center " }}>
						<AvatarGroup
							sx={{
								"& .MuiAvatar-root": {
									border: "2px solid var(--mui-palette-neutral-950)",
								},
							}}>
							<Avatar
								alt="User 5"
								src="/new-mangomap-assets/avatar-5.png"
							/>
							<Avatar
								alt="User 1"
								src="/new-mangomap-assets/avatar-1.png"
							/>
							<Avatar
								alt="User 2"
								src="/new-mangomap-assets/avatar-2.png"
							/>
						</AvatarGroup>
						<Box>
							<Typography
								color="neutral.400"
								component="span"
								sx={{ fontWeight: 700 }}
								variant="subtitle2">
								“Saved us $10K a year and made map updates a breeze.”
							</Typography>
							<br />
							<Typography
								color="neutral.400"
								sx={{ whiteSpace: "nowrap" }}
								variant="body2">
								— Trusted by counties and townships like Columbus, Plumas, and
								Stowe.
							</Typography>
						</Box>
					</Stack>
				</Stack>
			</Container>
			<Container
				maxWidth="lg"
				sx={{ position: "relative", zIndex: 2 }}>
				<Box
					component="img"
					src="https://mangomap.com/_next/image?url=%2Fnew-mangomap-assets%2Fimages%2FmangoGIS.png&w=1080&q=75"
					sx={{
						display: "block",
						height: "auto",
						position: "relative",
						width: "100%",
						zIndex: 2,
					}}
				/>
			</Container>
			<Container
				maxWidth="xl"
				sx={{ pt: "64px" }}>
				<Stack spacing={3}>
					<Typography
						color="text.secondary"
						component="p"
						sx={{ textAlign: "center" }}
						variant="overline">
						Used by companies like:
					</Typography>
					<Stack
						direction="row"
						spacing={3}
						sx={{
							alignItems: "center",
							borderTop: "1px solid var(--mui-palette-divider)",
							borderBottom: "1px solid var(--mui-palette-divider)",
							color: "var(--mui-palette-neutral-500)",
							flexWrap: "wrap",
							justifyContent: "center",
							py: "16px",
						}}>
						<Stack
							direction="row"
							spacing={5}
							sx={{
								alignItems: "center",
								color: "var(--mui-palette-neutral-500)",
								flexWrap: "wrap",
								justifyContent: "center",
								filter: "grayscale(1) opacity(0.7)",
							}}>
							<Box
								component="img"
								src="https://mangomap.com/_next/image?url=%2Fnew-mangomap-assets%2Fimages%2Fsensar.png&w=128&q=75"
							/>
							<Box
								component="img"
								src="https://mangomap.com/new-mangomap-assets/svg/gis4ag.svg"
								sx={{ height: "50px" }}
							/>
							<Box
								component="img"
								src="https://mangomap.com/_next/image?url=%2Fnew-mangomap-assets%2Fimages%2Fgeotech-info.png&w=128&q=75"
							/>
							<Box
								component="img"
								src="https://mangomap.com/new-mangomap-assets/svg/enerko.svg"
								sx={{ height: "50px" }}
							/>
							<Box
								component="img"
								src="https://mangomap.com/new-mangomap-assets/svg/swift.svg"
								sx={{ height: "50px" }}
							/>
							<Box
								component="img"
								src="https://mangomap.com/new-mangomap-assets/svg/gbi.svg"
								sx={{ height: "50px" }}
							/>
							<Box
								component="img"
								src="https://mangomap.com/_next/image?url=%2Fnew-mangomap-assets%2Fimages%2Fplumas.png&w=128&q=75"
							/>
						</Stack>
					</Stack>
				</Stack>
			</Container>
		</Box>
	);
}
