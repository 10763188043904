import * as React from "react";

import { Contact as ContactPage } from "~landing/components/contact/contact";
import { Layout } from "~landing/components/layout";

export function Contact(): React.JSX.Element {
	return (
		<Layout>
			<main>
				<ContactPage />
			</main>
		</Layout>
	);
}
