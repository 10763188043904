import * as React from "react";

import { Layout } from "~landing/components/layout";
import { Privacy } from "~landing/components/privacy/privacy";

export function PrivacyPolicy(): React.JSX.Element {
	return (
		<Layout>
			<main>
				<Privacy />
			</main>
		</Layout>
	);
}
