import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { graphierQLNextClient } from "utils";

import {
	SubmitContactFormMutation,
	SubmitContactFormMutationVariables,
} from "~landing/types/__generated/gql/graphql";

const query = /* GraphQL */ `
	mutation SubmitContactForm(
		$input: SubmitContactFormInput!
		$recaptchaToken: String!
	) {
		submitContactForm(input: $input, recaptchaToken: $recaptchaToken)
	}
`;

export type SubmitContactFormResults =
	SubmitContactFormMutation["submitContactForm"];
export type SubmitContactFormVariables = SubmitContactFormMutationVariables;

export const useSubmitContactForm = (): UseMutationResult<
	SubmitContactFormResults,
	Error,
	SubmitContactFormVariables
> => {
	return useMutation({
		mutationKey: ["submitContactForm"],
		mutationFn: (variables: SubmitContactFormVariables) =>
			graphierQLNextClient<
				SubmitContactFormMutation,
				SubmitContactFormVariables
			>({
				query,
				variables,
				useKey: true,
			}).then((res) => res.submitContactForm),
	});
};
