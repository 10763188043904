import * as React from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {
	CaretLeft as CaretLeftIcon,
	CaretRight as CaretRightIcon,
	Users as UsersIcon,
	Quotes as QuoteIcon,
} from "@phosphor-icons/react";
import useEmblaCarousel from "embla-carousel-react";

import type { EmblaCarouselType } from "embla-carousel";

const reviews = [
	{
		id: "REV-5",
		author: "Jim R., ",
		title: "GIS Pro, Columbus County",
		logo: "https://mangomap.com/new-mangomap-assets/svg/wrha.svg",
		comment:
			"Swapped ESRI for this and cut our budget in half—updates are a snap now.",
		outcome:
			"Columbus County saved $12K annually and streamlined map updates by 60%.",
	},
	{
		id: "REV-4",
		author: "Linda T.",
		title: "Mapping Lead, Plumas County",
		logo: "https://mangomap.com/new-mangomap-assets/svg/mostrom.svg",
		comment:
			"Residents love the clean maps, and my committee's finally off my back.",
		outcome:
			"Plumas County boosted resident satisfaction by 40% with branded portals.",
	},
	{
		id: "REV-2",
		author: "Sarah M.",
		title: "GIS Coordinator, City of Dyersburg",
		logo: "https://mangomap.com/_next/image?url=%2Fnew-mangomap-assets%2Fimages%2Fbusgeo.png&w=96&q=75",
		comment:
			"Custom popups made our zoning maps a hit—procurement was painless.",
		outcome: "Dyersburg improved zoning clarity and sped up approvals by 50%.",
	},
	{
		id: "REV-1",
		author: "Ellen W.",
		title: "Mapping Expert, Town of Stettler",
		logo: "https://mangomap.com/new-mangomap-assets/svg/burgex.svg",
		comment: "Accurate election maps in half the time—worth every penny.",
		outcome: "Stettler cut election map prep by 50% and kept voters informed.",
	},
] as const;

export function Testimonials(): React.JSX.Element {
	const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });
	const [prevBtnDisabled, setPrevBtnDisabled] = React.useState(true);
	const [nextBtnDisabled, setNextBtnDisabled] = React.useState(true);
	const [selectedIndex, setSelectedIndex] = React.useState(0);
	const [scrollSnaps, setScrollSnaps] = React.useState<number[]>([]);

	const scrollPrev = React.useCallback(() => {
		emblaApi?.scrollPrev();
	}, [emblaApi]);

	const scrollNext = React.useCallback(() => {
		emblaApi?.scrollNext();
	}, [emblaApi]);

	const scrollTo = React.useCallback(
		(index: number) => {
			emblaApi?.scrollTo(index);
		},
		[emblaApi],
	);

	const onInit = React.useCallback((api: EmblaCarouselType) => {
		setScrollSnaps(api.scrollSnapList());
	}, []);

	const onSelect = React.useCallback((api: EmblaCarouselType) => {
		setSelectedIndex(api.selectedScrollSnap());
		setPrevBtnDisabled(!api.canScrollPrev());
		setNextBtnDisabled(!api.canScrollNext());
	}, []);

	React.useEffect(() => {
		if (!emblaApi) return;

		onInit(emblaApi);
		onSelect(emblaApi);
		emblaApi.on("reInit", onInit);
		emblaApi.on("reInit", onSelect);
		emblaApi.on("select", onSelect);
	}, [emblaApi, onInit, onSelect]);

	return (
		<Box
			sx={{
				bgcolor: "var(--mui-palette-background-level1)",
				borderTop: "1px solid var(--mui-palette-divider)",
				borderBottom: "1px solid var(--mui-palette-divider)",
				py: "120px",
			}}>
			<Stack
				spacing={6}
				sx={{ maxWidth: "1200px", mx: "auto", px: 3 }}>
				<Stack
					spacing={2}
					sx={{ textAlign: "center" }}>
					<Box sx={{ display: "flex", justifyContent: "center" }}>
						<Chip
							color="primary"
							icon={<UsersIcon />}
							label="Success stories"
							variant="soft"
						/>
					</Box>
					<Typography variant="h3">What County Officials Are Saying</Typography>
					<Typography
						variant="body1"
						color="text.secondary"
						sx={{ maxWidth: "700px", mx: "auto" }}>
						Real results from real counties that switched to Mango Maps
					</Typography>
				</Stack>

				<Box sx={{ position: "relative" }}>
					<Box
						ref={emblaRef}
						sx={{
							overflow: "hidden",
							borderRadius: 2,
						}}>
						<Box
							sx={{
								display: "flex",
								touchAction: "pan-y",
							}}>
							{reviews.map((review) => (
								<Box
									key={review.id}
									sx={{
										flex: "0 0 100%",
										minWidth: 0,
										position: "relative",
										px: 2,
									}}>
									<Paper
										elevation={3}
										sx={{
											borderRadius: 4,
											overflow: "hidden",
											height: "100%",
											display: "flex",
											flexDirection: { xs: "column", md: "row" },
											alignItems: "stretch",
										}}>
										<Box
											sx={{
												bgcolor: "primary.main",
												color: "primary.contrastText",
												p: 4,
												display: "flex",
												flexDirection: "column",
												justifyContent: "center",
												alignItems: "center",
												width: { xs: "100%", md: "35%" },
											}}>
											<Stack
												spacing={3}
												alignItems="center">
												<Avatar
													src={review.logo}
													alt={`${review.author} logo`}
													sx={{
														width: 80,
														height: 80,
														bgcolor: "white",
														p: 1,
													}}
												/>
												<Stack
													spacing={1}
													alignItems="center">
													<Typography variant="h6">{review.author}</Typography>
													<Typography variant="body2">
														{review.title}
													</Typography>
												</Stack>
											</Stack>
										</Box>

										<Box
											sx={{
												p: 4,
												flex: 1,
												display: "flex",
												flexDirection: "column",
												justifyContent: "center",
											}}>
											<Stack spacing={3}>
												<Box sx={{ display: "flex" }}>
													<QuoteIcon
														size={32}
														color="var(--mui-palette-primary-dark)"
													/>
												</Box>
												<Typography variant="h4">{review.comment}</Typography>
												<Typography
													variant="body1"
													sx={{
														p: 2,
														bgcolor: "action.hover",
														borderRadius: 2,
														borderColor: "primary.main",
													}}>
													<strong>Result:</strong> {review.outcome}
												</Typography>
											</Stack>
										</Box>
									</Paper>
								</Box>
							))}
						</Box>
					</Box>

					<Box
						sx={{
							position: "absolute",
							top: "50%",
							transform: "translateY(-50%)",
							left: { xs: "15%", sm: 0 },
							ml: { xs: "-80px", sm: "-20px" },
							mt: { xs: "180px", sm: 0 },
							zIndex: 1,
						}}>
						<IconButton
							disabled={prevBtnDisabled}
							onClick={scrollPrev}
							sx={{
								bgcolor: "background.paper",
								boxShadow: 2,
								"&:hover": { bgcolor: "background.paper" },
							}}>
							<CaretLeftIcon weight="bold" />
						</IconButton>
					</Box>

					<Box
						sx={{
							position: "absolute",
							top: "50%",
							transform: "translateY(-50%)",
							right: { xs: "15%", sm: 0 },
							mr: { xs: "-80px", sm: "-20px" },
							mt: { xs: "180px", sm: 0 },
							zIndex: 1,
						}}>
						<IconButton
							disabled={nextBtnDisabled}
							onClick={scrollNext}
							sx={{
								bgcolor: "background.paper",
								boxShadow: 2,
								"&:hover": { bgcolor: "background.paper" },
							}}>
							<CaretRightIcon weight="bold" />
						</IconButton>
					</Box>
				</Box>

				<Stack
					direction="row"
					spacing={1}
					sx={{ justifyContent: "center" }}>
					{scrollSnaps.map((_, index) => (
						<Box
							// eslint-disable-next-line react/no-array-index-key -- index is stable and tied to slide position
							key={index}
							onClick={() => {
								scrollTo(index);
							}}
							sx={{
								bgcolor:
									index === selectedIndex ? "primary.main" : "action.selected",
								borderRadius: "50%",
								cursor: "pointer",
								height: "10px",
								width: "10px",
								transition: "all 0.2s ease",
								"&:hover": {
									bgcolor: "primary.light",
								},
							}}
						/>
					))}
				</Stack>
			</Stack>
		</Box>
	);
}
