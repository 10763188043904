import * as React from "react";
import Divider from "@mui/material/Divider";

import { FeaturesSection } from "~landing/components/pricing/features";
import { PlansTable } from "~landing/components/pricing/plans-table";
import { StartBuilding } from "~landing/components/pricing/start-building";
import { Layout } from "~landing/components/layout";

export function Pricing(): React.JSX.Element {
	return (
		<Layout>
			<main>
				<PlansTable />
				<Divider />
				<FeaturesSection />
				<StartBuilding />
			</main>
		</Layout>
	);
}
