import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "ui-components";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef } from "react";

import { sendAnalyticsEvent } from "~utils/providers/analytics-provider";
import { useSubmitContactForm } from "~landing/queries/useSubmitContactForm";

export function ContactForm() {
	const recaptchaRef = useRef<ReCAPTCHA>(null);
	const submitContactForm = useSubmitContactForm();
	const form = useFormik({
		validateOnMount: true,
		initialValues: {
			name: "",
			email: "",
			phone: "",
			company: "",
			teamSize: "",
			source: "",
			sourceDetails: "",
			message: "",
		},
		validationSchema: Yup.object({
			name: Yup.string().required("Required"),
			email: Yup.string().email("Invalid email address").required("Required"),
			phone: Yup.string().notRequired(),
			company: Yup.string().notRequired(),
			teamSize: Yup.string().required("Required"),
			source: Yup.string().required("Required"),
			sourceDetails: Yup.string().when("source", (source, schema) => {
				if (isDetailsRequired(source)) {
					return schema.required("Required");
				}

				return schema.notRequired();
			}),
			message: Yup.string().required("Required"),
		}),
		onSubmit: (values, helpers) => {
			toast.promise(
				async () => {
					const recaptchaToken = await recaptchaRef.current!.executeAsync();
					if (!recaptchaToken) throw new Error("Failed to get recaptcha token");

					await submitContactForm.mutateAsync({
						input: values,
						recaptchaToken,
					});
					sendAnalyticsEvent("submitContact");
					helpers.resetForm();
				},
				{
					loading: "Submitting...",
					success: "Your message has been sent!",
					error: (e) => {
						console.log("🚀 ~ ContactForm ~ e:", e);
						return "Something went wrong!";
					},
				},
			);
		},
	});

	const darkInputStyles = {
		color: "white",
		backgroundColor: "rgba(255, 255, 255, 0.1)",
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "white",
		},
	};
	return (
		<Box>
			<Stack
				spacing={1}
				component="form"
				onSubmit={form.handleSubmit}>
				<Grid
					container
					spacing={2}>
					<Grid
						sm={12}
						xs={12}>
						<FormControl fullWidth>
							<InputLabel
								required
								sx={{ color: "white" }}>
								Name
							</InputLabel>
							<OutlinedInput
								type="text"
								size="small"
								sx={darkInputStyles}
								{...form.getFieldProps("name")}
								error={!!form.touched.name && !!form.errors.name}
							/>
							{!!form.touched.name && !!form.errors.name && (
								<FormHelperText error>{form.errors.name}</FormHelperText>
							)}
						</FormControl>
					</Grid>
					<Grid
						sm={12}
						xs={12}>
						<FormControl fullWidth>
							<InputLabel
								required
								sx={{ color: "white" }}>
								Email address
							</InputLabel>
							<OutlinedInput
								type="email"
								size="small"
								sx={darkInputStyles}
								{...form.getFieldProps("email")}
								error={!!form.touched.email && !!form.errors.email}
							/>
							{!!form.touched.email && !!form.errors.email && (
								<FormHelperText error>{form.errors.email}</FormHelperText>
							)}
						</FormControl>
					</Grid>
					<Grid
						sm={12}
						xs={12}>
						<FormControl fullWidth>
							<InputLabel sx={{ color: "white" }}>Phone number</InputLabel>
							<OutlinedInput
								type="tel"
								size="small"
								sx={darkInputStyles}
								{...form.getFieldProps("phone")}
								error={!!form.touched.phone && !!form.errors.phone}
							/>
							{!!form.touched.phone && !!form.errors.phone && (
								<FormHelperText error>{form.errors.phone}</FormHelperText>
							)}
						</FormControl>
					</Grid>
					<Grid
						sm={12}
						xs={12}>
						<FormControl fullWidth>
							<InputLabel sx={{ color: "white" }}>Company</InputLabel>
							<OutlinedInput
								type="text"
								size="small"
								sx={darkInputStyles}
								{...form.getFieldProps("company")}
							/>
							{!!form.touched.company && !!form.errors.company && (
								<FormHelperText error>{form.errors.company}</FormHelperText>
							)}
						</FormControl>
					</Grid>
					<Grid
						sm={12}
						xs={12}>
						<FormControl fullWidth>
							<InputLabel sx={{ color: "white" }}>Team size</InputLabel>
							<Select
								size="small"
								sx={darkInputStyles}
								{...form.getFieldProps("teamSize")}
								error={!!form.touched.teamSize && !!form.errors.teamSize}>
								<MenuItem value="1-10">1-10</MenuItem>
								<MenuItem value="11-50">11-50</MenuItem>
								<MenuItem value="51-200">51-200</MenuItem>
								<MenuItem value="201-500">201-500</MenuItem>
								<MenuItem value="500+">500+</MenuItem>
							</Select>
							{!!form.touched.teamSize && !!form.errors.teamSize && (
								<FormHelperText error>{form.errors.teamSize}</FormHelperText>
							)}
						</FormControl>
					</Grid>
					<Grid
						sm={12}
						xs={12}>
						<FormControl fullWidth>
							<InputLabel sx={{ color: "white" }}>
								How did you hear about us?
							</InputLabel>
							<Select
								size="small"
								sx={darkInputStyles}
								{...form.getFieldProps("source")}
								error={!!form.touched.source && !!form.errors.source}>
								<MenuItem value="search">Search Engine</MenuItem>
								<MenuItem value="social">Social Media</MenuItem>
								<MenuItem value="referral">Referral</MenuItem>
								<MenuItem value="event">Event/Webinar</MenuItem>
								<MenuItem value="Other">Other</MenuItem>
							</Select>
							{!!form.touched.source && !!form.errors.source && (
								<FormHelperText error>{form.errors.source}</FormHelperText>
							)}
							{isDetailsRequired(form.values.source) && (
								<Box mt={2}>
									<FormControl fullWidth>
										<InputLabel sx={{ color: "white" }}>
											Please specify
										</InputLabel>
										<OutlinedInput
											type="text"
											size="small"
											sx={darkInputStyles}
											{...form.getFieldProps("sourceDetails")}
											error={
												!!form.touched.sourceDetails &&
												!!form.errors.sourceDetails
											}
										/>
										{!!form.touched.sourceDetails &&
											!!form.errors.sourceDetails && (
												<FormHelperText error>
													{form.errors.sourceDetails}
												</FormHelperText>
											)}
									</FormControl>
								</Box>
							)}
						</FormControl>
					</Grid>
					<Grid
						sm={12}
						xs={12}>
						<FormControl fullWidth>
							<InputLabel sx={{ color: "white" }}>Message</InputLabel>
							<OutlinedInput
								multiline
								rows={4}
								size="small"
								sx={darkInputStyles}
								{...form.getFieldProps("message")}
								error={!!form.touched.message && !!form.errors.message}
							/>
							{!!form.touched.message && !!form.errors.message && (
								<FormHelperText error>{form.errors.message}</FormHelperText>
							)}
						</FormControl>
					</Grid>
					{/* @ts-ignore */}
					<ReCAPTCHA
						size="invisible"
						ref={recaptchaRef}
						sitekey={process.env.GOOGLE_RECAPTCHA_SITE_KEY!}
					/>
				</Grid>
				<Grid
					sm={12}
					xs={12}>
					<Stack
						direction="column"
						py={2}
						spacing={2}>
						<Button
							type="submit"
							fullWidth
							size="large"
							variant="contained">
							Let&apos;s talk
						</Button>
						<Typography
							color="text.secondary"
							variant="body2">
							By submitting your message, you agree to our{" "}
							<Link
								color="text.secondary"
								href="/privacy"
								underline="always"
								variant="subtitle2">
								privacy
							</Link>{" "}
							and{" "}
							<Link
								color="text.secondary"
								href="/security"
								underline="always"
								variant="subtitle2">
								data security policies.
							</Link>
							.
						</Typography>
					</Stack>
				</Grid>
			</Stack>
		</Box>
	);
}

const isDetailsRequired = (source: string) => {
	return ["referral", "event", "Other"].includes(source);
};
