import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
	ArrowsClockwise,
	CloudArrowUp,
	Layout,
	MapTrifold,
	ShareNetwork,
	Star,
} from "@phosphor-icons/react";

export function HowItWorks(): React.JSX.Element {
	const howItWorks = {
		image: "/new-mangomap-assets/home-howitworks.jpg",
		steps: [
			{
				id: "import",
				eyebrow: "Import",
				icon: <CloudArrowUp />,
				title: "Add your data",
				description:
					"Drop in your GIS files—surveys, shapefiles, whatever you've got. Mango handles it securely and quickly, setting you up to map in no time.",
			},
			{
				id: "design",
				eyebrow: "Design",
				icon: <MapTrifold />,
				title: "Build your maps",
				description:
					"Turn raw data into interactive maps with a few clicks. No tech headaches—just intuitive tools that let your GIS skills shine.",
			},
			{
				id: "distribute",
				eyebrow: "Distribute",
				icon: <ShareNetwork />,
				title: "Share with ease",
				description:
					"Send a secure link to clients, teams, or the public. They get instant access, you get fewer follow-ups—everyone's happy.",
			},
			{
				id: "update",
				eyebrow: "Update",
				icon: <ArrowsClockwise />,
				title: "Keep it current",
				description:
					"Add new data as it comes in. Mango syncs updates instantly across all maps, keeping your work fresh without extra effort.",
			},
			{
				id: "organize",
				eyebrow: "Organize",
				icon: <Layout />,
				title: "Manage like a boss",
				description:
					"Track every project in one place. Add team members, tweak access, and stay in control—no clutter, just clarity.",
			},
			{
				id: "delight",
				eyebrow: "Delight",
				icon: <Star />,
				title: "Win raving fans",
				description:
					"Stakeholders love the accessibility and reliability. You save time, they stay impressed—building trust that grows your reputation.",
			},
		],
	};

	return (
		<div>
			<Container
				maxWidth="lg"
				sx={{ py: "120px" }}>
				<Stack spacing={8}>
					<Container
						maxWidth="lg"
						sx={{ position: "relative", zIndex: 2 }}>
						<Box
							component="img"
							src={howItWorks.image}
							sx={{
								display: "block",
								height: "auto",
								position: "relative",
								width: "100%",
								zIndex: 2,
								boxShadow: 8,
								borderRadius: "20px",
							}}
						/>
					</Container>
					<Container maxWidth="lg">
						<Stack
							direction={{ xs: "column", sm: "row" }}
							spacing={3}
							sx={{
								flexWrap: "wrap",
								justifyContent: "center",
								alignItems: "stretch",
							}}
							useFlexGap>
							{howItWorks.steps.map((step) => (
								<Card
									key={step.id}
									raised
									sx={{
										flex: { xs: "1 1 100%", md: "1 1 30%" },
										maxWidth: { xs: "100%", md: "30%" },
										mb: 3,
									}}>
									<CardContent>
										<Chip
											color="success"
											icon={step.icon}
											label={step.eyebrow}
											size="small"
											variant="soft"
										/>
										<Typography
											component="div"
											py={2}
											variant="h5">
											{step.title}
										</Typography>
										<Typography
											color="text.secondary"
											variant="body2">
											{step.description}
										</Typography>
									</CardContent>
								</Card>
							))}
						</Stack>
					</Container>
				</Stack>
			</Container>
		</div>
	);
}
