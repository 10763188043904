import * as React from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
	CaretLeft as CaretLeftIcon,
	CaretRight as CaretRightIcon,
	Quotes as QuoteIcon,
} from "@phosphor-icons/react";
import useEmblaCarousel from "embla-carousel-react";

import type { EmblaCarouselType } from "embla-carousel";

interface Review {
	id: string;
	author: string;
	title: string;
	logo: string;
	comment: string;
	outcome: string;
}

const reviews: Review[] = [
	{
		id: "REV-5",
		author: "Dr. Chris Green",
		title: "Snr Epidemiologist, WRHA",
		logo: "/new-mangomap-assets/wrha.svg",
		comment:
			"Love Mango... compared to ArcGIS online, it provides better visual mapping and a straightforward pricing model.",
		outcome:
			"Increased stakeholder engagement by 60% thanks to dynamic map presentations, setting a new benchmark in health innovation.",
	},
	{
		id: "REV-4",
		author: "Hope Kallio",
		title: "GIS Specialist at Mostrom & Associates",
		logo: "/new-mangomap-assets/mostrom.svg",
		comment:
			"Mango does what we need it to do, at a fraction of the cost of its competitors.",
		outcome:
			"Enhanced cost efficiency and accelerated project delivery by 35%, giving teams a competitive edge.",
	},
	{
		id: "REV-3",
		author: "Emily Selphe",
		title: "Charlevoix County, CA",
		logo: "/new-mangomap-assets/charlevoix.png",
		comment:
			"A great out-of-the-box product—professional results with minimal time and money spent.",
		outcome:
			"Enabled rapid parcel and zoning analysis, reducing project timelines by 25% for Charlevoix County.",
	},
	{
		id: "REV-2",
		author: "Phil Henry",
		title: "Business Geographics & Qikmaps",
		logo: "/new-mangomap-assets/busgeo.png",
		comment:
			"I like the analogy that Mango use: sometimes it's better to use a scalpel than a swiss army knife.",
		outcome:
			"Drove client acquisition by 45% over a year, proving the value of targeted, affordable mapping solutions.",
	},
	{
		id: "REV-1",
		author: "Jake Tanner",
		title: "Lead GIS Developer/Analyst, Burgex, Inc.",
		logo: "/new-mangomap-assets/burgex.svg",
		comment:
			"Great functionality for client visualisation and easy distribution. It's a game-changer.",
		outcome:
			"Optimized map-sharing workflows, cutting distribution time by 50% and elevating client satisfaction.",
	},
] satisfies Review[];

export function Testimonials(): React.JSX.Element {
	const testimonials = {
		eyebrow: "Success stories",
		icon: <QuoteIcon />,
	};

	const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });
	const [prevBtnDisabled, setPrevBtnDisabled] = React.useState(true);
	const [nextBtnDisabled, setNextBtnDisabled] = React.useState(true);
	const [selectedIndex, setSelectedIndex] = React.useState(0);
	const [scrollSnaps, setScrollSnaps] = React.useState<number[]>([]);

	const scrollPrev = React.useCallback(() => {
		emblaApi?.scrollPrev();
	}, [emblaApi]);

	const scrollNext = React.useCallback(() => {
		emblaApi?.scrollNext();
	}, [emblaApi]);

	const scrollTo = React.useCallback(
		(index: number) => {
			emblaApi?.scrollTo(index);
		},
		[emblaApi],
	);

	const onInit = React.useCallback((api: EmblaCarouselType) => {
		setScrollSnaps(api.scrollSnapList());
	}, []);

	const onSelect = React.useCallback((api: EmblaCarouselType) => {
		setSelectedIndex(api.selectedScrollSnap());
		setPrevBtnDisabled(!api.canScrollPrev());
		setNextBtnDisabled(!api.canScrollNext());
	}, []);

	React.useEffect(() => {
		if (!emblaApi) return;

		onInit(emblaApi);
		onSelect(emblaApi);
		emblaApi.on("reInit", onInit);
		emblaApi.on("reInit", onSelect);
		emblaApi.on("select", onSelect);
	}, [emblaApi, onInit, onSelect]);

	return (
		<Box
			sx={{
				bgcolor: "var(--mui-palette-background-level0)",
				py: "120px",
			}}>
			<Stack
				spacing={6}
				sx={{ maxWidth: "1200px", mx: "auto", px: 3 }}>
				<Stack
					spacing={2}
					sx={{ textAlign: "center" }}>
					<Box sx={{ display: "flex", justifyContent: "center" }}>
						<Chip
							color="secondary"
							icon={testimonials.icon}
							label={testimonials.eyebrow}
							size="small"
							variant="soft"
						/>
					</Box>
				</Stack>
				<Box sx={{ position: "relative" }}>
					<Box
						ref={emblaRef}
						sx={{
							overflow: "hidden",
							borderRadius: 2,
						}}>
						<Box
							sx={{
								display: "flex",
								touchAction: "pan-y",
							}}>
							{reviews.map((review) => (
								<Box
									key={review.id}
									sx={{
										flex: "0 0 100%",
										minWidth: 0,
										position: "relative",
										px: 2,
									}}>
									<Paper
										elevation={8}
										sx={{
											borderRadius: 4,
											overflow: "hidden",
											height: "100%",
											display: "flex",
											flexDirection: { xs: "column", md: "row" },
											alignItems: "stretch",
										}}>
										<Box
											sx={{
												p: 4,
												display: "flex",
												flexDirection: "column",
												justifyContent: "center",
												alignItems: "center",
												width: { xs: "100%", md: "35%" },
											}}>
											<Stack
												alignItems="center"
												spacing={3}>
												<Box
													sx={{
														width: 150,
														height: 150,
														display: "flex",
														alignItems: "center",
														justifyContent: "center",
													}}>
													<Box
														alt={`${review.author} logo`}
														component="img"
														src={review.logo}
														sx={{
															maxWidth: "100%",
															maxHeight: "100%",
															objectFit: "contain",
														}}
													/>
												</Box>
												<Stack
													alignItems="center"
													spacing={1}>
													<Typography variant="h6">{review.author}</Typography>
													<Typography variant="body2">
														{review.title}
													</Typography>
												</Stack>
											</Stack>
										</Box>
										<Box
											sx={{
												p: 4,
												flex: 1,
												display: "flex",
												flexDirection: "column",
												justifyContent: "center",
											}}>
											<Stack spacing={3}>
												<Box sx={{ display: "flex" }}>
													<QuoteIcon
														color="primary.contrastText"
														size={32}
													/>
												</Box>
												<Typography variant="h4">{review.comment}</Typography>
												<Typography
													sx={{
														p: 2,
														bgcolor: "success.main",
														borderRadius: 2,
														color: "primary.contrastText",
													}}
													variant="body1">
													<strong>Result:</strong> {review.outcome}
												</Typography>
											</Stack>
										</Box>
									</Paper>
								</Box>
							))}
						</Box>
					</Box>
					<Box
						sx={{
							position: "absolute",
							top: "50%",
							transform: "translateY(-50%)",
							left: { xs: "15%", sm: 0 },
							ml: { xs: "-80px", sm: "-20px" },
							mt: { xs: "180px", sm: 0 },
							zIndex: 1,
						}}>
						<IconButton
							disabled={prevBtnDisabled}
							onClick={scrollPrev}
							sx={{
								bgcolor: "background.paper",
								boxShadow: 2,
								"&:hover": { bgcolor: "background.paper" },
							}}>
							<CaretLeftIcon weight="bold" />
						</IconButton>
					</Box>
					<Box
						sx={{
							position: "absolute",
							top: "50%",
							transform: "translateY(-50%)",
							right: { xs: "15%", sm: 0 },
							mr: { xs: "-80px", sm: "-20px" },
							mt: { xs: "180px", sm: 0 },
							zIndex: 1,
						}}>
						<IconButton
							disabled={nextBtnDisabled}
							onClick={scrollNext}
							sx={{
								bgcolor: "background.paper",
								boxShadow: 2,
								"&:hover": { bgcolor: "background.paper" },
							}}>
							<CaretRightIcon weight="bold" />
						</IconButton>
					</Box>
				</Box>
				<Stack
					direction="row"
					spacing={1}
					sx={{ justifyContent: "center" }}>
					{scrollSnaps.map((_, index) => (
						<Box
							// eslint-disable-next-line react/no-array-index-key -- index is stable and tied to slide position
							key={index}
							onClick={() => {
								scrollTo(index);
							}}
							sx={{
								bgcolor:
									index === selectedIndex ? "primary.main" : "action.selected",
								borderRadius: "50%",
								cursor: "pointer",
								height: "10px",
								width: "10px",
								transition: "all 0.2s ease",
								"&:hover": {
									bgcolor: "primary.light",
								},
							}}
						/>
					))}
				</Stack>
			</Stack>
		</Box>
	);
}
