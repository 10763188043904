import * as React from "react";

import { Faqs } from "~landing/components/environment/faqs";
import { Hero } from "~landing/components/environment/hero";
import { Benefits } from "~landing/components/environment/benefits";
import { UseCases } from "~landing/components/environment/use-cases";
import { StartBuilding } from "~landing/components/environment/start-building";
import { Testimonials } from "~landing/components/environment/testimonials";
import { HowItWorks } from "~landing/components/environment/how-it-works";
import { Layout } from "~landing/components/layout";

export function SolutionsEnvironment(): React.JSX.Element {
	return (
		<Layout>
			<main>
				<Hero />
				<Benefits />
				<UseCases />
				<Testimonials />
				<HowItWorks />
				<Faqs />
				<StartBuilding />
			</main>
		</Layout>
	);
}
