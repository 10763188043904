import * as React from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { Compass } from "@phosphor-icons/react";

export function UseCases(): React.JSX.Element {
	return (
		<div>
			<Container
				maxWidth="lg"
				sx={{ py: "120px" }}>
				<Stack spacing={8}>
					<Stack
						maxWidth="700px"
						spacing={2}
						sx={{ mx: "auto" }}>
						<Typography
							sx={{ textAlign: "center" }}
							variant="h3">
							Maps That Solve Real County Needs
						</Typography>
						<Typography
							color="text.secondary"
							sx={{ textAlign: "center" }}>
							Whether you&apos;re plotting field data, wowing clients with
							spatial insights, or engaging the public with interactive maps,
							Mango transforms your everyday GIS challenges into opportunities
							for success.
						</Typography>
					</Stack>
					<Grid
						container
						maxWidth="xl"
						spacing={4}>
						<Grid
							sm={6}
							xs={12}>
							<Card raised>
								<CardMedia
									alt="green iguana"
									component="img"
									height="200"
									image="/new-mangomap-assets/home-publish.webp"
									sx={{
										width: "100%",
										objectFit: "cover",
									}}
								/>
								<CardContent>
									<Stack spacing={2}>
										<div>
											<Chip
												color="info"
												icon={<Compass />}
												label="Publish"
												size="small"
												variant="soft"
											/>
										</div>
										<Typography
											component="div"
											variant="h5">
											Tax Parcel Precision
										</Typography>
										<Typography
											sx={{ color: "text.secondary" }}
											variant="body2">
											Turn field data into interactive maps fast, making it a
											breeze to share insights with teams or clients on the
											ground—desktop or mobile.
										</Typography>
									</Stack>
								</CardContent>
								<CardActions>
									<Button
										color="secondary"
										href="https://mangomap.com/orange-county/maps/87210/parcel-query-advanced#"
										size="small"
										target="_blank"
										variant="outlined">
										View example
									</Button>
								</CardActions>
							</Card>
						</Grid>
						<Grid
							sm={6}
							xs={12}>
							<Card raised>
								<CardMedia
									alt="green iguana"
									component="img"
									height="200"
									image="/new-mangomap-assets/home-publish.webp"
									sx={{
										width: "100%",
										objectFit: "cover",
									}}
								/>
								<CardContent sx={{ flex: "1 0 auto" }}>
									<Stack spacing={2}>
										<div>
											<Chip
												color="info"
												icon={<Compass />}
												label="Publish"
												size="small"
												variant="soft"
											/>
										</div>
										<Typography
											component="div"
											variant="h5">
											Emergency Response Ready
										</Typography>
										<Typography
											sx={{ color: "text.secondary" }}
											variant="body2">
											Create E911 maps that guide first responders fast and
											flawlessly.
										</Typography>
									</Stack>
								</CardContent>
								<CardActions>
									<Button
										color="secondary"
										href="https://mangomap.com/orange-county/maps/87210/parcel-query-advanced#"
										size="small"
										target="_blank"
										variant="outlined">
										View example
									</Button>
								</CardActions>
							</Card>
						</Grid>
						<Grid
							sm={6}
							xs={12}>
							<Card
								raised
								sx={{ alignItems: "stretch" }}>
								<CardMedia
									alt="green iguana"
									component="img"
									height="200"
									image="/new-mangomap-assets/home-publish.webp"
									sx={{
										width: "100%",
										objectFit: "cover",
									}}
								/>
								<CardContent>
									<Stack spacing={2}>
										<div>
											<Chip
												color="info"
												icon={<Compass />}
												label="Publish"
												size="small"
												variant="soft"
											/>
										</div>
										<Typography
											component="div"
											variant="h5">
											Resident-Friendly Updates
										</Typography>
										<Typography
											sx={{ color: "text.secondary" }}
											variant="body2">
											Keep community maps current with minimal effort or cost.
										</Typography>
									</Stack>
								</CardContent>
								<CardActions>
									<Button
										color="secondary"
										href="https://mangomap.com/orange-county/maps/87210/parcel-query-advanced#"
										size="small"
										target="_blank"
										variant="outlined">
										View example
									</Button>
								</CardActions>
							</Card>
						</Grid>
						<Grid
							sm={6}
							xs={12}>
							<Card
								raised
								sx={{ alignItems: "stretch" }}>
								<CardMedia
									alt="green iguana"
									component="img"
									height="200"
									image="/new-mangomap-assets/home-publish.webp"
									sx={{
										width: "100%",
										objectFit: "cover",
									}}
								/>
								<CardContent>
									<Stack spacing={2}>
										<div>
											<Chip
												color="info"
												icon={<Compass />}
												label="Publish"
												size="small"
												variant="soft"
											/>
										</div>
										<Typography
											component="div"
											variant="h5">
											Committee-Winning Portals
										</Typography>
										<Typography
											sx={{ color: "text.secondary" }}
											variant="body2">
											Build a branded, reliable portal that breezes through
											approvals.
										</Typography>
									</Stack>
								</CardContent>
								<CardActions>
									<Button
										color="secondary"
										href="https://mangomap.com/orange-county/maps"
										size="small"
										target="_blank"
										variant="outlined">
										View example
									</Button>
								</CardActions>
							</Card>
						</Grid>
					</Grid>
				</Stack>
				<Stack
					p={4}
					spacing={8}>
					<div>
						<Button
							color="secondary"
							href="https://mangomap.com/orange-county/maps"
							size="large"
							target="_blank"
							variant="contained">
							View example
						</Button>
					</div>
				</Stack>
			</Container>
		</div>
	);
}
