import * as React from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import {
	ArrowsClockwise,
	Lightning,
	PiggyBank,
	UsersThree,
} from "@phosphor-icons/react";

export function Benefits(): React.JSX.Element {
	return (
		<Box
			sx={{
				bgcolor: "var(--mui-palette-neutral-100)",
				color: "var(--mui-palette-common-white)",
				overflow: "hidden",
				py: "120px",
				position: "relative",
			}}>
			<Box
				sx={{
					alignItems: "center",
					display: "flex",
					height: "100%",
					justifyContent: "center",
					left: 0,
					position: "absolute",
					top: 0,
					width: "100%",
					zIndex: 0,
				}}>
				<Box
					component="img"
					src="/new-mangomap-assets/home-cosmic.svg"
					sx={{ height: "auto", width: "1600px" }}
				/>
			</Box>
			<Stack
				spacing={8}
				sx={{ position: "relative", zIndex: 1 }}>
				<Container maxWidth="md">
					<Stack spacing={2}>
						<Typography
							color="text.primary"
							sx={{ textAlign: "center" }}
							variant="h3">
							Deliver Eco-Innovations Faster, Cheaper, and Smarter
						</Typography>
						<Typography
							color="text.secondary"
							sx={{ textAlign: "center" }}>
							Launch compelling portals from drone and field data in days, not
							months—no tech overhead.
						</Typography>
					</Stack>
				</Container>
				<Container maxWidth="lg">
					<Grid
						alignItems="center"
						container
						spacing={3}>
						<Grid
							md={5}
							xs={12}>
							<Stack spacing={2}>
								<div>
									<Chip
										color="success"
										icon={<PiggyBank />}
										label="Save"
										variant="soft"
									/>
								</div>
								<Typography
									color="text.primary"
									variant="h3">
									Stakeholder-Ready Simplicity
								</Typography>
								<Typography color="text.secondary">
									Visually engaging maps that non-technical funders and clients
									grasp instantly.
								</Typography>
							</Stack>
						</Grid>
						<Grid
							md={7}
							xs={12}>
							<Box
								sx={{
									margin: "0 auto",
									maxWidth: "100%",
									position: "relative",
									width: "390px",
								}}>
								<Box
									alt="Widgets"
									component="img"
									src="https://placehold.co/390"
									sx={{
										height: "auto",
										position: "relative",
										width: "100%",
										zIndex: 1,
										borderRadius: "20px",
									}}
								/>
							</Box>
						</Grid>
					</Grid>

					<Grid
						alignItems="center"
						container
						spacing={3}>
						<Grid
							md={7}
							xs={12}>
							<Box
								sx={{
									margin: "0 auto",
									maxWidth: "100%",
									position: "relative",
									width: "390px",
								}}>
								<Box
									alt="Widgets"
									component="img"
									src="https://placehold.co/390"
									sx={{
										height: "auto",
										position: "relative",
										width: "100%",
										zIndex: 1,
										borderRadius: "20px",
									}}
								/>
							</Box>
						</Grid>
						<Grid
							md={5}
							xs={12}>
							<Stack spacing={2}>
								<div>
									<Chip
										color="success"
										icon={<Lightning />}
										label="Speed"
										variant="soft"
									/>
								</div>
								<Typography
									color="text.primary"
									variant="h3">
									Ditch the delivery delays
								</Typography>
								<Typography color="text.secondary">
									With one click, send secure, interactive maps to clients or
									teams. No more slow emails or static files—just instant access
									that saves you time and impresses stakeholders.
								</Typography>
							</Stack>
						</Grid>
					</Grid>

					<Grid
						alignItems="center"
						container
						spacing={3}>
						<Grid
							md={5}
							xs={12}>
							<Stack spacing={2}>
								<div>
									<Chip
										color="success"
										icon={<ArrowsClockwise />}
										label="Sync"
										variant="soft"
									/>
								</div>
								<Typography
									color="text.primary"
									variant="h3">
									No need to start over
								</Typography>
								<Typography color="text.secondary">
									Mango integrates smoothly with your existing GIS tools and
									data formats, letting you dive in without wrestling new tech
									or disrupting your setup.
								</Typography>
							</Stack>
						</Grid>
						<Grid
							md={7}
							xs={12}>
							<Box
								sx={{
									margin: "0 auto",
									maxWidth: "100%",
									position: "relative",
									width: "390px",
								}}>
								<Box
									alt="Widgets"
									component="img"
									src="https://placehold.co/390"
									sx={{
										height: "auto",
										position: "relative",
										width: "100%",
										zIndex: 1,
										borderRadius: "20px",
									}}
								/>
							</Box>
						</Grid>
					</Grid>

					<Grid
						alignItems="center"
						container
						spacing={3}>
						<Grid
							md={7}
							xs={12}>
							<Box
								sx={{
									margin: "0 auto",
									maxWidth: "100%",
									position: "relative",
									width: "390px",
								}}>
								<Box
									alt="Widgets"
									component="img"
									src="https://placehold.co/390"
									sx={{
										height: "auto",
										position: "relative",
										width: "100%",
										zIndex: 1,
										borderRadius: "20px",
									}}
								/>
							</Box>
						</Grid>
						<Grid
							md={5}
							xs={12}>
							<Stack spacing={2}>
								<div>
									<Chip
										color="success"
										icon={<UsersThree />}
										label="Team"
										variant="soft"
									/>
								</div>
								<Typography
									color="text.primary"
									variant="h3">
									Budget-Friendly Pricing
								</Typography>
								<Typography color="text.secondary">
									Clear, affordable plans fit tight grant cycles—no more
									justifying bloated software costs.
								</Typography>
							</Stack>
						</Grid>
					</Grid>
				</Container>
			</Stack>
		</Box>
	);
}
