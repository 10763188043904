import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { graphierQLNextClient } from "utils";

import {
	SubmitFranchiseContactFormMutation,
	SubmitFranchiseContactFormMutationVariables,
} from "~landing/types/__generated/gql/graphql";

const query = /* GraphQL */ `
	mutation SubmitFranchiseContactForm(
		$input: SubmitFranchiseContactFormInput!
		$recaptchaToken: String!
	) {
		submitFranchiseContactForm(input: $input, recaptchaToken: $recaptchaToken)
	}
`;

export type SubmitFranchiseContactFormResults =
	SubmitFranchiseContactFormMutation["submitFranchiseContactForm"];
export type SubmitFranchiseContactFormVariables =
	SubmitFranchiseContactFormMutationVariables;

export const useSubmitFranchiseContactForm = (): UseMutationResult<
	SubmitFranchiseContactFormResults,
	Error,
	SubmitFranchiseContactFormVariables
> => {
	return useMutation({
		mutationKey: ["submitFranchiseContactForm"],
		mutationFn: (variables: SubmitFranchiseContactFormVariables) =>
			graphierQLNextClient<
				SubmitFranchiseContactFormMutation,
				SubmitFranchiseContactFormVariables
			>({
				query,
				variables,
				useKey: true,
			}).then((res) => res.submitFranchiseContactForm),
	});
};
