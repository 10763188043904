import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Airplane } from "@phosphor-icons/react";

export function HowItWorks(): React.JSX.Element {
	return (
		<div>
			<Container
				maxWidth="lg"
				sx={{ py: "120px" }}>
				<Stack spacing={8}>
					<Container
						maxWidth="lg"
						sx={{ position: "relative", zIndex: 2 }}>
						<Box
							component="img"
							src="https://mangomap.com/_next/image?url=%2Fnew-mangomap-assets%2Fimages%2FmangoGIS.png&w=1080&q=75"
							sx={{
								display: "block",
								height: "auto",
								position: "relative",
								width: "100%",
								zIndex: 2,
							}}
						/>
					</Container>
					<Container maxWidth="xl">
						<Stack
							direction={{ xs: "column", sm: "row" }}
							spacing={1}
							sx={{
								justifyContent: "space-evenly",
								alignItems: "stretch",
							}}
							useFlexGap>
							<Card
								raised
								sx={{
									flex: { xs: "1 1 100%", md: "1 1 25%" },
									maxWidth: { xs: "100%", md: "25%" },
								}}>
								<CardContent>
									<Chip
										color="primary"
										icon={<Airplane />}
										label="Step"
										size="small"
										variant="soft"
									/>
									<Typography
										component="div"
										py={2}
										variant="h5">
										Define
									</Typography>
									<Typography
										color="text.secondary"
										variant="body2">
										Define a profile of an ideal territory by reviewing the
										criteria you have set or have used in the past.
									</Typography>
								</CardContent>
							</Card>
							<Card
								raised
								sx={{
									flex: { xs: "1 1 100%", md: "1 1 25%" },
									maxWidth: { xs: "100%", md: "25%" },
								}}>
								<CardContent>
									<Chip
										color="primary"
										icon={<Airplane />}
										label="Step"
										size="small"
										variant="soft"
									/>
									<Typography
										component="div"
										py={2}
										variant="h5">
										Plan
									</Typography>
									<Typography
										color="text.secondary"
										variant="body2">
										Review your development plans and prepare a territory
										mapping solution to support the plan.
									</Typography>
								</CardContent>
							</Card>
							<Card
								raised
								sx={{
									flex: { xs: "1 1 100%", md: "1 1 25%" },
									maxWidth: { xs: "100%", md: "25%" },
								}}>
								<CardContent>
									<Chip
										color="primary"
										icon={<Airplane />}
										label="Step"
										size="small"
										variant="soft"
									/>
									<Typography
										component="div"
										py={2}
										variant="h5">
										Demographics
									</Typography>
									<Typography
										color="text.secondary"
										variant="body2">
										Identify and gather the latest demographic data for
										reporting, mapping & territory creation.
									</Typography>
								</CardContent>
							</Card>
							<Card
								raised
								sx={{
									flex: { xs: "1 1 100%", md: "1 1 25%" },
									maxWidth: { xs: "100%", md: "25%" },
								}}>
								<CardContent>
									<Chip
										color="primary"
										icon={<Airplane />}
										label="Step"
										size="small"
										variant="soft"
									/>
									<Typography
										component="div"
										py={2}
										variant="h5">
										POI
									</Typography>
									<Typography
										color="text.secondary"
										variant="body2">
										Identify and gather POI such as competitor locations, anchor
										tenants and your own locations.
									</Typography>
								</CardContent>
							</Card>
						</Stack>
					</Container>
				</Stack>
			</Container>
		</div>
	);
}
