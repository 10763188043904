import * as React from "react";
import RouterLink from "next/link";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";

import type { NavItemConfig } from "~ui-components/types/nav";
import { MangoLogo } from "~ui-components/components/atoms/mango-logo";

interface GroupConfig {
	key: string;
	title: string;
	items: NavItemConfig[];
}

const groups = [
	{
		key: "solutions",
		title: "Solutions",
		items: [
			{ key: "gis", title: "Mango for GIS", href: "/" },
			{
				key: "franchise",
				title: "Mango for Franchises",
				href: "/franchise",
			},
		],
	},
	{
		key: "company",
		title: "Company",
		items: [
			{
				key: "documentation",
				title: "Documentation",
				external: true,
				href: "https://help.mangomap.com",
			},
			{ key: "about", title: "About Mango", href: "/about-us" },
			{ key: "contact", title: "Contact", href: "/contact" },
		],
	},
	{
		key: "social",
		title: "Connect",
		items: [
			{
				key: "linkedin",
				title: "LinkedIn",
				href: "https://www.linkedin.com/company/mangomap",
				external: true,
			},
			{
				key: "yt",
				title: "YouTube",
				href: "https://www.youtube.com/@MangoMap",
				external: true,
			},
		],
	},
] satisfies GroupConfig[];

export function Footer(): React.JSX.Element {
	return (
		<Box
			component="footer"
			sx={{
				bgcolor: "var(--mui-palette-background-default)",
				borderTop: "1px solid var(--mui-palette-divider)",
				pb: 6,
				pt: { md: 15, xs: 6 },
			}}>
			<Container maxWidth="lg">
				<Grid
					container
					spacing={3}>
					<Grid
						md={3}
						sm={4}
						sx={{ order: { xs: 4, md: 1 } }}
						xs={12}>
						<Stack spacing={1}>
							<MangoLogo
								height={32}
								width={122}
							/>
						</Stack>
					</Grid>
					{groups.map((section, index) => (
						<Grid
							key={section.key}
							md={3}
							sm={6}
							sx={{ order: { md: index + 2, xs: index + 1 } }}
							xs={12}>
							<Typography
								color="text.secondary"
								variant="overline">
								{section.title}
							</Typography>
							<Stack
								// component="p"
								spacing={1}
								sx={{ m: 0, p: 0 }}>
								{section.items.map((item) => (
									<NavItem
										{...item}
										key={item.key}
									/>
								))}
							</Stack>
						</Grid>
					))}
				</Grid>

				<Divider sx={{ my: 6 }} />
				<Stack
					direction="row"
					spacing={3}>
					<Link
						color="text.secondary"
						href="/terms"
						variant="caption">
						Terms of Service
					</Link>
					<Link
						color="text.secondary"
						href="/privacy"
						variant="caption">
						Privacy Policy
					</Link>
					<Link
						color="text.secondary"
						href="/security"
						variant="caption">
						Data Security
					</Link>
				</Stack>
				<Typography
					color="text.secondary"
					variant="caption">
					© {new Date().getFullYear()} Mangomap LIMITED. 1 Victoria Street,
					Bristol BS1 6AA, UK. +44 117 230 1999
				</Typography>
				<div>
					{" "}
					<Typography
						color="text.secondary"
						variant="caption">
						When you visit our site, we or our authorized providers may use
						cookies to enhance your experience, improve speed, security, and for
						marketing purposes.
					</Typography>
				</div>
			</Container>
		</Box>
	);
}

function NavItem({ href, external, title }: NavItemConfig): React.JSX.Element {
	return (
		<Stack
			direction="row"
			key={title}
			spacing={1}
			sx={{ alignItems: "center" }}>
			<Link
				{...(href
					? external
						? { component: "a", href, target: "_blank" }
						: { component: RouterLink, href }
					: {})}
				color="text.secondary"
				variant="caption">
				{title}
			</Link>
		</Stack>
	);
}
