import * as React from "react";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export function Security(): React.JSX.Element {
	return (
		<Container maxWidth="lg">
			<Stack
				spacing={2}
				sx={{ py: 4 }}>
				<Typography
					color="text.primary"
					gutterBottom
					variant="h1">
					Data Security, Processing & Storage
				</Typography>
				<Typography variant="caption">
					Last modified on 25 October 2023
				</Typography>
				<Typography
					color="text.primary"
					variant="h3">
					Securing Your Data with MangoMap
				</Typography>
				<Typography
					color="text.primary"
					component="p"
					variant="body1">
					MangoMap takes the security of your data very seriously, employing
					reputable third-party vendors and hosting partners to furnish the
					essential hardware, software, networking, and storage resources
					crucial for delivering our services. While we retain ownership of the
					code, databases, and the service rights, you hold all rights to your
					user data.
				</Typography>
				<Typography
					color="text.primary"
					component="p"
					variant="body1">
					Your data is instantly recorded on multiple disks, backed up daily,
					and housed in various locations to ensure robust data integrity and
					availability. User data uploaded to our service is hosted on servers
					engineered to eliminate bottlenecks and failure points, ensuring
					smooth operation at all times. Rest assured, your data remains
					untouched by MangoMap, its staff, or representatives, and is not
					shared with any third parties, except as described in our Privacy
					Policy.
				</Typography>
				<Typography
					color="text.primary"
					variant="h5">
					Leveraging Amazon Web Services (AWS)
				</Typography>
				<Typography
					color="text.primary"
					component="p"
					variant="body1">
					We ingest and securely store your data on our servers using Amazon Web
					Services (AWS) for data storage, benefiting from Amazon’s stringent
					security and environmental controls. Within AWS, we utilize RDS
					Postgres (us-east region) with AES-256 block-level storage encryption
					both in transit and at rest to ensure the utmost security.
				</Typography>
				<Typography
					color="text.primary"
					variant="h5">
					Physical and System Security
				</Typography>
				<Typography
					color="text.primary"
					component="p"
					variant="body1">
					Our data centers are well-guarded with limited access only to Amazon
					AWS technicians. Security measures include:
				</Typography>
				<ul color="text.primary">
					<li>
						<Typography
							component="p"
							variant="body1">
							Biometric scanning for controlled data center access
						</Typography>
					</li>
					<li>
						<Typography
							component="p"
							variant="body1">
							Surveillance cameras at all data center locations
						</Typography>
					</li>
					<li>
						<Typography
							component="p"
							variant="body1">
							24/7 onsite staff to deter unauthorized entry
						</Typography>
					</li>
					<li>
						<Typography
							component="p"
							variant="body1">
							Unmarked facilities to maintain a low profile
						</Typography>
					</li>
					<li>
						<Typography
							color="text.primary"
							component="p"
							variant="body1">
							Independent audits of physical security measures
						</Typography>
					</li>
				</ul>
				<Typography
					color="text.primary"
					component="p"
					variant="body1">
					The system security is further bolstered with:
				</Typography>
				<ul color="text.primary">
					<li>
						<Typography
							component="p"
							variant="body1">
							Hardened, patched OS for system installation
						</Typography>
					</li>
					<li>
						<Typography
							component="p"
							variant="body1">
							Regular system patching by Amazon AWS to counter exploits
						</Typography>
					</li>
					<li>
						<Typography
							component="p"
							variant="body1">
							Dedicated firewall, VPN services, and intrusion detection devices
							to thwart unauthorized access
						</Typography>
					</li>
					<li>
						<Typography
							component="p"
							variant="body1">
							Amazon AWS managed backup solutions for data protection
						</Typography>
					</li>
				</ul>
				<Typography
					color="text.primary"
					variant="h5">
					Operational Security
				</Typography>
				<Typography
					color="text.primary"
					component="p"
					variant="body1">
					We adhere to ISO 27001:2013-based policies and procedures, with our
					systems access logged and tracked for auditing purposes. Our
					change-management procedures are fully documented, and we have
					independently audited disaster recovery and business continuity plans
					in place.
				</Typography>
				<Typography
					color="text.primary"
					component="p"
					variant="subtitle1">
					Internal Security Practices
				</Typography>
				<Typography
					color="text.primary"
					component="p"
					variant="body1">
					At Mango, we maintain stringent key management policies:
				</Typography>
				<ul color="text.primary">
					<li>
						<Typography
							component="p"
							variant="body1">
							Master access keys are not distributed
						</Typography>
					</li>
					<li>
						<Typography
							component="p"
							variant="body1">
							Access keys are never stored in version control systems or as
							plaintext
						</Typography>
					</li>
					<li>
						<Typography
							component="p"
							variant="body1">
							Individual access keys are generated per employee for
							developer-only access
						</Typography>
					</li>
				</ul>
				<Typography
					component="p"
					variant="body1">
					Our workstations are secured with encryption for data storage,
					anti-malware, and antivirus software. Client data is always anonymized
					for development purposes, and all Mango employees are well-versed in
					best practice security standards with granular role access to
					resources.
				</Typography>
				<Typography
					color="text.primary"
					component="p"
					variant="subtitle1">
					Employee Access
				</Typography>
				<Typography
					color="text.primary"
					component="p"
					variant="body1">
					MangoMap ensures employee awareness on security standards by
					instructing all its employees on best practice security standards and
					granting them granular role access to resources. Employee access to
					private repositories of user data is tightly controlled and only
					granted with consent from an authorized account holder for technical
					support purposes.
				</Typography>
				<Typography
					color="text.primary"
					component="p"
					variant="subtitle1">
					Data Transfer
				</Typography>
				<Typography
					color="text.primary"
					component="p"
					variant="body1">
					We ensure that all server requests to Mango are encrypted via SSL
					(secure socket layer) with a 256-bit encryption validated by GeoTrust.
				</Typography>
				<Typography
					color="text.primary"
					component="p"
					variant="subtitle1">
					Vulnerability Disclosure
				</Typography>
				<Typography
					color="text.primary"
					component="p"
					variant="body1">
					Mango GIS values a collaborative approach to security. While we
					don&apos;t have a bounty bug program, we highly appreciate the efforts
					of security researchers. We encourage responsible disclosure of any
					potential security issues, ensuring a safer environment for all our
					users.
				</Typography>
				<Typography
					color="text.primary"
					variant="h4">
					Disaster Recovery
				</Typography>
				<Typography
					color="text.primary"
					component="p"
					variant="body1">
					MangoMap conducts daily backups of all data, a practice that serves as
					a robust contingency measure ensuring a recent backup is always on
					hand in the event of unexpected data loss. The backup data is securely
					housed across multiple locations, a strategy designed to fortify
					security and guarantee data availability, even in the unlikely
					scenario where one of the storage sites encounters an issue. Our
					servers, the custodians of your data, are engineered using advanced
					techniques to eradicate potential bottlenecks and failure points,
					thereby facilitating seamless data retrieval and backup operations.
					Beyond the daily data backups, we maintain a full replica of the
					server, inclusive of the operating system and all data, ready to
					restore normalcy swiftly in the rare event of a critical server
					failure.
				</Typography>
				<Typography
					color="text.primary"
					component="p"
					variant="subtitle1">
					In Case of Data Loss or Security Breach
				</Typography>
				<Typography
					color="text.primary"
					component="p"
					variant="body1">
					In the unlikely event of data loss or a potential security breach, we
					will contact you as soon as possible, keeping you informed in
					real-time as we assess and address the situation to secure and recover
					your data. A comprehensive incident report will be provided should any
					incidents occur.
				</Typography>
				<Typography
					color="text.primary"
					variant="h5">
					Policy Updates
				</Typography>
				<Typography
					color="text.primary"
					component="p"
					variant="body1">
					We may update this policy periodically to reflect any significant
					changes. You will be notified via the primary email address specified
					in your Mango primary account holder account or through a prominent
					notice on our site. For our EU-based customers, find more information
					about our{" "}
					<Link
						href="https://help.mangomap.com/hc/en-us/articles/360022124272-General-Data-Protection-Regulation-GDPR"
						target="_blank">
						GDPR compliance here
					</Link>
					.
				</Typography>
			</Stack>
			<Stack
				spacing={2}
				sx={{ py: 4 }}>
				<Typography variant="h6">
					How can you contact us about this notice?
				</Typography>

				<Typography>
					If you have any questions or concerns about this security information
					please contact us.
				</Typography>

				<Typography>
					support@mangomap.com
					<br />
					1 Victoria Street
					<br />
					Bristol, BS1 6AA
					<br />
					United Kingdom
				</Typography>
			</Stack>
		</Container>
	);
}
