export * from "./views/about-us";
export * from "./views/contact";
export * from "./views/franchise/environment";
export * from "./views/franchise/home";
export * from "./views/home";
export * from "./views/pricing";
export * from "./views/privacy";
export * from "./views/security";
export * from "./views/solutions/environment";
export * from "./views/solutions/government";
export * from "./views/terms";
