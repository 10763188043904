import * as React from "react";

import { About } from "~landing/components/about/about";
import { Layout } from "~landing/components/layout";

export function AboutUs(): React.JSX.Element {
	return (
		<Layout>
			<main>
				<About />
			</main>
		</Layout>
	);
}
