import * as React from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import {
	ArrowsClockwise,
	Lightning,
	PiggyBank,
	UsersThree,
} from "@phosphor-icons/react";

export function Benefits(): React.JSX.Element {
	return (
		<Box
			sx={{
				bgcolor: "var(--mui-palette-neutral-100)",
				color: "var(--mui-palette-common-white)",
				overflow: "hidden",
				py: "120px",
				position: "relative",
			}}>
			<Box
				sx={{
					alignItems: "center",
					display: "flex",
					height: "100%",
					justifyContent: "center",
					left: 0,
					position: "absolute",
					top: 0,
					width: "100%",
					zIndex: 0,
				}}>
				<Box
					component="img"
					src="/new-mangomap-assets/home-cosmic.svg"
					sx={{ height: "auto", width: "1600px" }}
				/>
			</Box>
			<Stack
				spacing={8}
				sx={{ position: "relative", zIndex: 1 }}>
				<Container maxWidth="md">
					<Stack spacing={2}>
						<Typography
							color="text.primary"
							sx={{ textAlign: "center" }}
							variant="h3">
							Affordable GIS That Works for Your Community
						</Typography>
						<Typography
							color="text.secondary"
							sx={{ textAlign: "center" }}>
							Annual and multi-year plans with clear costs—no budget shocks or
							procurement hassles.
						</Typography>
					</Stack>
				</Container>
				<Container maxWidth="lg">
					<Grid
						alignItems="center"
						container
						spacing={3}>
						<Grid
							md={5}
							xs={12}>
							<Stack spacing={2}>
								<div>
									<Chip
										color="success"
										icon={<PiggyBank />}
										label="Save"
										variant="soft"
									/>
								</div>
								<Typography
									color="text.primary"
									variant="h3">
									Fixed Pricing, No Surprises
								</Typography>
								<Typography color="text.secondary">
									Mango cuts out ESRI’s steep licensing fees and hidden extras,
									offering pro-grade mapping at a fraction of the cost—perfect
									for budget-conscious GIS pros.
								</Typography>
							</Stack>
						</Grid>
						<Grid
							md={7}
							xs={12}>
							<Box
								sx={{
									margin: "0 auto",
									maxWidth: "100%",
									position: "relative",
									width: "390px",
								}}>
								<Box
									alt="Widgets"
									component="img"
									src="https://placehold.co/390"
									sx={{
										height: "auto",
										position: "relative",
										width: "100%",
										zIndex: 1,
										borderRadius: "20px",
									}}
								/>
							</Box>
						</Grid>
					</Grid>

					<Grid
						alignItems="center"
						container
						spacing={3}>
						<Grid
							md={7}
							xs={12}>
							<Box
								sx={{
									margin: "0 auto",
									maxWidth: "100%",
									position: "relative",
									width: "390px",
								}}>
								<Box
									alt="Widgets"
									component="img"
									src="https://placehold.co/390"
									sx={{
										height: "auto",
										position: "relative",
										width: "100%",
										zIndex: 1,
										borderRadius: "20px",
									}}
								/>
							</Box>
						</Grid>
						<Grid
							md={5}
							xs={12}>
							<Stack spacing={2}>
								<div>
									<Chip
										color="success"
										icon={<Lightning />}
										label="Speed"
										variant="soft"
									/>
								</div>
								<Typography
									color="text.primary"
									variant="h3">
									Ditch the Bloat
								</Typography>
								<Typography color="text.secondary">
									Swap expensive, clunky esri tools for a lean platform that
									gets the job done.
								</Typography>
							</Stack>
						</Grid>
					</Grid>

					<Grid
						alignItems="center"
						container
						spacing={3}>
						<Grid
							md={5}
							xs={12}>
							<Stack spacing={2}>
								<div>
									<Chip
										color="success"
										icon={<ArrowsClockwise />}
										label="Sync"
										variant="soft"
									/>
								</div>
								<Typography
									color="text.primary"
									variant="h3">
									No need to start over
								</Typography>
								<Typography color="text.secondary">
									Mango integrates smoothly with your existing GIS tools and
									data formats, letting you dive in without wrestling new tech
									or disrupting your setup.
								</Typography>
							</Stack>
						</Grid>
						<Grid
							md={7}
							xs={12}>
							<Box
								sx={{
									margin: "0 auto",
									maxWidth: "100%",
									position: "relative",
									width: "390px",
								}}>
								<Box
									alt="Widgets"
									component="img"
									src="https://placehold.co/390"
									sx={{
										height: "auto",
										position: "relative",
										width: "100%",
										zIndex: 1,
										borderRadius: "20px",
									}}
								/>
							</Box>
						</Grid>
					</Grid>

					<Grid
						alignItems="center"
						container
						spacing={3}>
						<Grid
							md={7}
							xs={12}>
							<Box
								sx={{
									margin: "0 auto",
									maxWidth: "100%",
									position: "relative",
									width: "390px",
								}}>
								<Box
									alt="Widgets"
									component="img"
									src="https://placehold.co/390"
									sx={{
										height: "auto",
										position: "relative",
										width: "100%",
										zIndex: 1,
										borderRadius: "20px",
									}}
								/>
							</Box>
						</Grid>
						<Grid
							md={5}
							xs={12}>
							<Stack spacing={2}>
								<div>
									<Chip
										color="success"
										icon={<UsersThree />}
										label="Team"
										variant="soft"
									/>
								</div>
								<Typography
									color="text.primary"
									variant="h3">
									Simple, Not Self-Hosted
								</Typography>
								<Typography color="text.secondary">
									Publish maps fast without server headaches—reliable and ready
									out of the box.
								</Typography>
							</Stack>
						</Grid>
					</Grid>

					<Grid
						alignItems="center"
						container
						spacing={3}>
						<Grid
							md={5}
							xs={12}>
							<Stack spacing={2}>
								<div>
									<Chip
										color="success"
										icon={<ArrowsClockwise />}
										label="Sync"
										variant="soft"
									/>
								</div>
								<Typography
									color="text.primary"
									variant="h3">
									Happy Residents, Happy Bosses
								</Typography>
								<Typography color="text.secondary">
									Deliver accurate, user-friendly maps that satisfy everyone,
									every time.
								</Typography>
							</Stack>
						</Grid>
						<Grid
							md={7}
							xs={12}>
							<Box
								sx={{
									margin: "0 auto",
									maxWidth: "100%",
									position: "relative",
									width: "390px",
								}}>
								<Box
									alt="Widgets"
									component="img"
									src="https://placehold.co/390"
									sx={{
										height: "auto",
										position: "relative",
										width: "100%",
										zIndex: 1,
										borderRadius: "20px",
									}}
								/>
							</Box>
						</Grid>
					</Grid>
				</Container>
			</Stack>
		</Box>
	);
}
