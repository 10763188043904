import * as React from "react";

import { Benefits } from "~landing/components/home/benefits";
import { Faqs } from "~landing/components/home/faqs";
import { Hero } from "~landing/components/home/hero";
import { HowItWorks } from "~landing/components/home/how-it-works";
import { StartBuilding } from "~landing/components/home/start-building";
import { Testimonials } from "~landing/components/home/testimonials";
import { UseCases } from "~landing/components/home/use-cases";
import { Layout } from "~landing/components/layout";

export function Home(): React.JSX.Element {
	return (
		<Layout>
			<main>
				<Hero />
				<Benefits />
				<UseCases />
				<Testimonials />
				<HowItWorks />
				<Faqs />
				<StartBuilding />
			</main>
		</Layout>
	);
}
