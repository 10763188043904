import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { useColorScheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { CaretRight as CaretRightIcon } from "@phosphor-icons/react";
import { signUpV1Or2 } from "config/paths";
import * as React from "react";

export function StartBuilding(): React.JSX.Element {
	const { colorScheme } = useColorScheme();

	return (
		<Box sx={{ bgcolor: "var(--mui-palette-background-level1)", pb: "60px" }}>
			<Container>
				<Box
					sx={{
						borderRadius: "20px",
						border: "1px solid transparent",
						color: "var(--mui-palette-common-white)",
						display: "flex",
						flexDirection: { xs: "column", md: "row" },
						overflow: "hidden",
						position: "relative",
					}}>
					<Box
						sx={{
							bgcolor: "var(--mui-palette-neutral-950)",
							borderRadius: "20px",
							bottom: "1px",
							left: "-1px",
							position: "absolute",
							right: "1px",
							top: "-1px",
							zIndex: 0,
						}}>
						<Box
							sx={{
								alignItems: "center",
								bottom: 0,
								display: "flex",
								justifyContent: "center",
								left: 0,
								position: "absolute",
								right: 0,
								top: 0,
								zIndex: 0,
							}}>
							<Box
								component="img"
								src="/new-mangomap-assets/home-cosmic.svg"
								sx={{ height: "auto", width: "1600px" }}
							/>
						</Box>
						<Box
							sx={{
								alignItems: "center",
								bottom: 0,
								display: "flex",
								justifyContent: "center",
								left: 0,
								position: "absolute",
								right: 0,
								top: 0,
								zIndex: 1,
							}}>
							<Box
								component="img"
								src="/new-mangomap-assets/home-rectangles.svg"
								sx={{ height: "auto", width: "1900px" }}
							/>
						</Box>
					</Box>
					<Stack
						spacing={3}
						sx={{
							flex: "0 1 auto",
							position: "relative",
							px: "64px",
							py: "120px",
							width: { xs: "100%", md: "45%" },
							zIndex: 1,
						}}>
						<Stack spacing={2}>
							<Typography
								color="inherit"
								variant="h3">
								Publish Eco-Impact Maps Without the Cost or Complexity
							</Typography>
							<Typography color="neutral.300">
								Ditch high-cost GIS stacks and slow workflows. Our no-code
								platform lets you turn drone data, field insights, and open
								sources into portals that win funding, wow stakeholders, and
								amplify your mission—all on a lean budget.
							</Typography>
						</Stack>
						<div>
							<Button
								endIcon={<CaretRightIcon />}
								href={signUpV1Or2}
								target="_blank"
								variant="contained">
								Start Your Free Trial
							</Button>
						</div>
						<div>
							<Typography
								variant="caption"
								color="neutral.300">
								We know the grind of tight budgets and big dreams. You&apos;re
								out there saving ecosystems, not wrestling software. We built
								Mango to cut the noise, so you can focus on impact. Our vision?
								Empower eco-advocates like you to shine. Let&apos;s do this.
							</Typography>
						</div>
					</Stack>
					<Box
						sx={{
							alignItems: { xs: "flex-end", md: "stretch" },
							borderRadius: "20px",
							display: "flex",
							flex: "1 1 auto",
							flexDirection: "column",
							justifyContent: "flex-end",
							pl: { xs: "64px", md: 0 },
							position: "relative",
							zIndex: 2,
						}}>
						<Box
							sx={{
								height: "340px",
								position: "relative",
								width: { xs: "80%", md: "100%" },
							}}>
							<Box
								sx={{
									bgcolor: "#8057f4",
									filter: "blur(50px)",
									height: "40px",
									left: 0,
									position: "absolute",
									right: 0,
									top: 0,
									zIndex: 0,
								}}
							/>
							<Box
								sx={{
									height: "100%",
									position: "relative",
									width: "100%",
									zIndex: 1,
								}}>
								<Box
									component="img"
									src={
										colorScheme === "dark"
											? "/new-mangomap-assets/home-cta-dark.png"
											: "/new-mangomap-assets/home-cta-light.png"
									}
									sx={{
										display: "block",
										bottom: "1px",
										height: "100%",
										left: 0,
										position: "absolute",
										width: "auto",
									}}
								/>
							</Box>
						</Box>
					</Box>
				</Box>
			</Container>
		</Box>
	);
}
