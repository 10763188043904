import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Airplane } from "@phosphor-icons/react";
import * as React from "react";

export function HowItWorks(): React.JSX.Element {
	return (
		<div>
			<Container
				maxWidth="lg"
				sx={{ py: "120px" }}>
				<Stack spacing={8}>
					<Container
						maxWidth="lg"
						sx={{ position: "relative", zIndex: 2 }}>
						<Box
							component="img"
							src="https://mangomap.com/_next/image?url=%2Fnew-mangomap-assets%2Fimages%2FmangoGIS.png&w=1080&q=75"
							sx={{
								display: "block",
								height: "auto",
								position: "relative",
								width: "100%",
								zIndex: 2,
							}}
						/>
					</Container>
					<Container maxWidth="lg">
						<Stack
							direction="column"
							spacing={3}
							sx={{
								flexWrap: "wrap",
								justifyContent: "center",
								alignItems: "center",
							}}
							useFlexGap>
							<Stack
								direction={{ xs: "column", sm: "row" }}
								spacing={3}
								sx={{
									justifyContent: "space-evenly",
									alignItems: "stretch",
									flexWrap: "wrap",
								}}
								useFlexGap>
								<Card
									raised
									sx={{
										flex: { xs: "1 1 100%", md: "1 1 30%" },
										maxWidth: { xs: "100%", md: "30%" },
									}}>
									<CardContent>
										<Chip
											color="primary"
											icon={<Airplane />}
											label="Step"
											size="small"
											variant="soft"
										/>
										<Typography
											component="div"
											py={2}
											variant="h5">
											Add Your Data
										</Typography>
										<Typography
											color="text.secondary"
											variant="body2">
											Drop in drone imagery, field collection spatial data, or
											open data — ready to map in minutes.
										</Typography>
									</CardContent>
								</Card>
								<Card
									raised
									sx={{
										flex: { xs: "1 1 100%", md: "1 1 30%" },
										maxWidth: { xs: "100%", md: "30%" },
									}}>
									<CardContent>
										<Chip
											color="primary"
											icon={<Airplane />}
											label="Step"
											size="small"
											variant="soft"
										/>
										<Typography
											component="div"
											py={2}
											variant="h5">
											Craft Your Portal
										</Typography>
										<Typography
											color="text.secondary"
											variant="body2">
											No-code tools turn complex datasets into stunning,
											interactive maps effortlessly. Ready to share with clients
											in hours, not weeks.
										</Typography>
									</CardContent>
								</Card>
								<Card
									raised
									sx={{
										flex: { xs: "1 1 100%", md: "1 1 30%" },
										maxWidth: { xs: "100%", md: "30%" },
									}}>
									<CardContent>
										<Chip
											color="primary"
											icon={<Airplane />}
											label="Step"
											size="small"
											variant="soft"
										/>
										<Typography
											component="div"
											py={2}
											variant="h5">
											Share Your Impact
										</Typography>
										<Typography
											color="text.secondary"
											variant="body2">
											Publish fast, impress funders and clients, and watch your
											eco-mission soar.
										</Typography>
									</CardContent>
								</Card>
							</Stack>
							<Stack
								direction={{ xs: "column", sm: "row" }}
								spacing={3}
								sx={{
									justifyContent: "space-evenly",
									alignItems: "stretch",
									flexWrap: "wrap",
								}}
								useFlexGap>
								<Card
									raised
									sx={{
										flex: { xs: "1 1 100%", md: "1 1 30%" },
										maxWidth: { xs: "100%", md: "30%" },
									}}>
									<CardContent>
										<Chip
											color="primary"
											icon={<Airplane />}
											label="Step"
											size="small"
											variant="soft"
										/>
										<Typography
											component="div"
											py={2}
											variant="h5">
											Keep It Current
										</Typography>
										<Typography
											color="text.secondary"
											variant="body2">
											Add new data as it comes in. Mango syncs updates instantly
											across all maps, keeping your work fresh without extra
											effort.
										</Typography>
									</CardContent>
								</Card>
								<Card
									raised
									sx={{
										flex: { xs: "1 1 100%", md: "1 1 30%" },
										maxWidth: { xs: "100%", md: "30%" },
									}}>
									<CardContent>
										<Chip
											color="primary"
											icon={<Airplane />}
											label="Step"
											size="small"
											variant="soft"
										/>
										<Typography
											component="div"
											py={2}
											variant="h5">
											Manage Like a Boss
										</Typography>
										<Typography
											color="text.secondary"
											variant="body2">
											Track every project in one place. Add team members, tweak
											access, and stay in control—no clutter, just clarity.
										</Typography>
									</CardContent>
								</Card>
								<Card
									raised
									sx={{
										flex: { xs: "1 1 100%", md: "1 1 30%" },
										maxWidth: { xs: "100%", md: "30%" },
									}}>
									<CardContent>
										<Chip
											color="primary"
											icon={<Airplane />}
											label="Step"
											size="small"
											variant="soft"
										/>
										<Typography
											component="div"
											py={2}
											variant="h5">
											Win Raving Fans
										</Typography>
										<Typography
											color="text.secondary"
											variant="body2">
											Stakeholders love the accessibility and reliability. You
											save time, they stay impressed—building trust that grows
											your reputation.
										</Typography>
									</CardContent>
								</Card>
							</Stack>
						</Stack>
					</Container>
				</Stack>
			</Container>
		</div>
	);
}
