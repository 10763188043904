import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Collapse from "@mui/material/Collapse";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CaretDown, CaretRight, Question } from "@phosphor-icons/react";

const faqs = [
	{
		id: "FAQ-1",
		question: "Does it integrate with my GIS tools?",
		answer:
			"Yes, seamlessly. Mango works with standard GIS formats and software, so you can plug it into your workflow without a hitch.",
	},
	{
		id: "FAQ-2",
		question: "Do I need coding skills to use it?",
		answer:
			"Not a bit. Mango's designed for GIS pros of all stripes—intuitive enough that you'll be mapping like a pro from day one.",
	},
	{
		id: "FAQ-3",
		question: "How secure is my data on Mango?",
		answer:
			"Fortress-level. With 256-bit encryption and top-notch data centre security, your maps and info are locked down tight.",
	},
	{
		id: "FAQ-4",
		question: "Will non-GIS users understand the maps?",
		answer:
			"Yes, easily. The light, navigable interface works on any device, making it simple for clients or the public to explore without help.",
	},
	{
		id: "FAQ-5",
		question: "Can I control who sees my maps?",
		answer:
			"You're in charge. Set precise access levels for teams, clients, or the public with a few clicks—privacy made simple.",
	},
	{
		id: "FAQ-6",
		question: "What's the free trial catch?",
		answer:
			"There isn't one. Try all Mango features completely free for 30 days, no credit card upfront. Cancel anytime if it's not your thing—no pressure.",
	},
	{
		id: "FAQ-7",
		question: "How does it save me time?",
		answer:
			"By simplifying map creation, automating updates, and cutting client back-and-forth, Mango frees up hours for what matters most.",
	},
] satisfies { id: string; question: string; answer: string }[];

export function Faqs(): React.JSX.Element {
	const faqContent = {
		eyebrow: "FAQ",
		heading: "Questions we get asked",
		subHeading: "Have another question you do not see here? Contact us.",
	};

	return (
		<Box sx={{ bgcolor: "var(--mui-palette-background-level1)", py: "120px" }}>
			<Container maxWidth="md">
				<Stack spacing={8}>
					<Stack
						maxWidth="700px"
						sx={{ mx: "auto" }}>
						<Stack spacing={2}>
							<Box sx={{ display: "flex", justifyContent: "center" }}>
								<Chip
									color="secondary"
									icon={<Question />}
									label={faqContent.eyebrow}
									size="small"
									variant="soft"
								/>
							</Box>
							<Typography
								sx={{ textAlign: "center" }}
								variant="h3">
								{faqContent.heading}
							</Typography>
							<Typography color="text.secondary">
								Have another question you do not see here?{" "}
								<Box
									component="a"
									href="/contact"
									sx={{ color: "inherit", textDecoration: "underline" }}>
									Contact us
								</Box>
								.
							</Typography>
						</Stack>
					</Stack>
					<Stack spacing={2}>
						{faqs.map((faq) => (
							<Faq
								key={faq.id}
								{...faq}
							/>
						))}
					</Stack>
				</Stack>
			</Container>
		</Box>
	);
}

export interface FaqProps {
	answer: string;
	question: string;
}

function Faq({ answer, question }: FaqProps): React.JSX.Element {
	const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

	return (
		<Card sx={{ p: 3 }}>
			<Stack
				onClick={() => {
					setIsExpanded((prevState) => !prevState);
				}}
				sx={{ cursor: "pointer" }}>
				<Stack
					direction="row"
					spacing={2}
					sx={{ alignItems: "center", justifyContent: "space-between" }}>
					<Typography variant="subtitle1">{question}</Typography>
					{isExpanded ? <CaretDown /> : <CaretRight />}
				</Stack>
				<Collapse in={isExpanded}>
					<Typography
						color="text.secondary"
						sx={{ pt: 3 }}
						variant="body2">
						{answer}
					</Typography>
				</Collapse>
			</Stack>
		</Card>
	);
}
