import * as React from "react";

import { Layout } from "~landing/components/layout";
import { Terms } from "~landing/components/terms/terms";

export function TermsOfService(): React.JSX.Element {
	return (
		<Layout>
			<main>
				<Terms />
			</main>
		</Layout>
	);
}
