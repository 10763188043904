import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Collapse from "@mui/material/Collapse";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CaretDown, CaretRight, Question } from "@phosphor-icons/react";

const faqs = [
	{
		id: "FAQ-1",
		question: "How’s the pricing work?",
		answer:
			"Fixed annual and multi-year plans—no surprises, perfect for county budgets.",
	},
	{
		id: "FAQ-2",
		question: "Do I need to self-host?",
		answer: "Nope—fully hosted, no servers or IT woes.",
	},
	{
		id: "FAQ-3",
		question: "Can I brand my maps?",
		answer: "Absolutely—custom logos, styles, and domains included.",
	},
	{
		id: "FAQ-4",
		question: "Will residents like it?",
		answer: "Simple, accurate maps keep end users satisfied.",
	},
	{
		id: "FAQ-5",
		question: "Does it fit procurement?",
		answer:
			"We know the procurement lifecycle, and can work with you from go to woah. Bank transfers and annual billing make it committee-friendly.",
	},
	{
		id: "FAQ-6",
		question: "Is it fast to set up?",
		answer: "From data to live map in hours, not weeks.",
	},
	{
		id: "FAQ-7",
		question: "Is it better than pricey GIS suites?",
		answer: "Cheaper, simpler, and tailored to small-town needs.",
	},
	{
		id: "FAQ-7",
		question: "What if I need help?",
		answer: "Your dedicated account manager is on hand to help you.",
	},
] satisfies { id: string; question: string; answer: string }[];

export function Faqs(): React.JSX.Element {
	return (
		<Box sx={{ bgcolor: "var(--mui-palette-background-level1)", py: "120px" }}>
			<Container maxWidth="md">
				<Stack spacing={8}>
					<Stack
						maxWidth="700px"
						sx={{ mx: "auto" }}>
						<Stack spacing={2}>
							<Box sx={{ display: "flex", justifyContent: "center" }}>
								<Chip
									color="primary"
									icon={<Question />}
									label="FAQ"
									variant="soft"
								/>
							</Box>
							<Typography
								sx={{ textAlign: "center" }}
								variant="h3">
								Questions we get asked
							</Typography>
							<Typography color="text.secondary">
								Have another question you do not see here?{" "}
								<Box
									component="a"
									href="/contact"
									sx={{ color: "inherit", textDecoration: "underline" }}>
									Contact us
								</Box>
								.
							</Typography>
						</Stack>
					</Stack>
					<Stack spacing={2}>
						{faqs.map((faq) => (
							<Faq
								key={faq.id}
								{...faq}
							/>
						))}
					</Stack>
				</Stack>
			</Container>
		</Box>
	);
}

export interface FaqProps {
	answer: string;
	question: string;
}

function Faq({ answer, question }: FaqProps): React.JSX.Element {
	const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

	return (
		<Card sx={{ p: 3 }}>
			<Stack
				onClick={() => {
					setIsExpanded((prevState) => !prevState);
				}}
				sx={{ cursor: "pointer" }}>
				<Stack
					direction="row"
					spacing={2}
					sx={{ alignItems: "center", justifyContent: "space-between" }}>
					<Typography variant="subtitle1">{question}</Typography>
					{isExpanded ? <CaretDown /> : <CaretRight />}
				</Stack>
				<Collapse in={isExpanded}>
					<Typography
						color="text.secondary"
						sx={{ pt: 3 }}
						variant="body2">
						{answer}
					</Typography>
				</Collapse>
			</Stack>
		</Card>
	);
}
