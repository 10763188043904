import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { Compass, Globe } from "@phosphor-icons/react";
import * as React from "react";

export function UseCases(): React.JSX.Element {
	return (
		<div>
			<Container
				maxWidth="lg"
				sx={{ py: "120px" }}>
				<Stack spacing={8}>
					<Stack
						maxWidth="700px"
						spacing={2}
						sx={{ mx: "auto" }}>
						<Typography
							sx={{ textAlign: "center" }}
							variant="h3">
							Deliver Results That Matter
						</Typography>
						<Typography
							color="text.secondary"
							sx={{ textAlign: "center" }}>
							Proven Outcomes to Amplify Your Eco-Mission
						</Typography>
					</Stack>

					<Grid
						container
						maxWidth="xl"
						spacing={2}>
						<Stack
							direction={{ xs: "column", lg: "row" }}
							spacing={12}
							sx={{
								p: 2,
								justifyContent: "space-evenly",
								alignItems: "center",
							}}>
							<Card raised>
								<CardMedia
									alt="green iguana"
									component="img"
									height="200"
									image="/new-mangomap-assets/home-publish.webp"
								/>

								<CardContent>
									<Stack spacing={2}>
										<div>
											<Chip
												color="info"
												icon={<Compass />}
												label="Publish"
												size="small"
												variant="soft"
											/>
										</div>
										<Typography
											component="div"
											variant="h5">
											Win Grants Fast
										</Typography>
										<Typography
											sx={{ color: "text.secondary" }}
											variant="body2">
											Transform drone imagery and field data into portals that
											secure funding quickly.
										</Typography>
									</Stack>
								</CardContent>
							</Card>
							<Card raised>
								<CardMedia
									alt="green iguana"
									component="img"
									height="200"
									image="https://mangomap.com/images/thumnails/188dd4c4-1afc-11e9-9955-06765ea3034e_big.png"
								/>

								<CardContent>
									<Stack spacing={2}>
										<div>
											<Chip
												color="info"
												icon={<Globe />}
												label="Connect"
												size="small"
												variant="soft"
											/>
										</div>
										<Typography
											component="div"
											variant="h5">
											Simplify Data
										</Typography>
										<Typography
											sx={{ color: "text.secondary" }}
											variant="body2">
											Turn scattered datasets into clear, interactive maps for
											non-technical buy-in.
										</Typography>
									</Stack>
								</CardContent>
							</Card>
						</Stack>
					</Grid>

					<Grid
						container
						maxWidth="xl"
						spacing={1}>
						<Stack
							direction={{ xs: "column", lg: "row" }}
							spacing={12}
							sx={{
								p: 2,
								justifyContent: "space-evenly",
								alignItems: "center",
							}}>
							<Card raised>
								<CardMedia
									alt="green iguana"
									component="img"
									height="200"
									image="/new-mangomap-assets/home-publish.webp"
								/>

								<CardContent>
									<Stack spacing={2}>
										<div>
											<Chip
												color="info"
												icon={<Compass />}
												label="Publish"
												size="small"
												variant="soft"
											/>
										</div>
										<Typography
											component="div"
											variant="h5">
											Track Conservation
										</Typography>
										<Typography
											sx={{ color: "text.secondary" }}
											variant="body2">
											Publish and update dynamic portals to monitor efforts
											without delays.
										</Typography>
									</Stack>
								</CardContent>
							</Card>
							<Card raised>
								<CardMedia
									alt="For fieldwork and surveys"
									component="img"
									height="200"
									image="https://mangomap.com/images/thumnails/647791ac-1129-11e6-8e77-22000bb3a3a1_big.png"
								/>

								<CardContent>
									<Stack spacing={2}>
										<div>
											<Chip
												color="info"
												icon={<Globe />}
												label="Connect"
												size="small"
												variant="soft"
											/>
										</div>
										<Typography
											component="div"
											variant="h5">
											Fuse Drone Data
										</Typography>
										<Typography
											sx={{ color: "text.secondary" }}
											variant="body2">
											Blend aerial insights with open sources for standout
											portals in days.
										</Typography>
									</Stack>
								</CardContent>
							</Card>
						</Stack>
					</Grid>
				</Stack>
			</Container>
		</div>
	);
}
