import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Collapse from "@mui/material/Collapse";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CaretDown, CaretRight, Question } from "@phosphor-icons/react";

const faqs = [
	{
		id: "FAQ-1",
		question:
			"How does your pricing fit my tight budgets, especially with grant-funded projects?",
		answer:
			"Our pricing is designed to work within the constraints of grant limits and tight budgets. You get affordable, transparent plans with no hidden fees or unexpected costs—every dollar is predictable, so you can confidently plan projects, stay profitable, and avoid financial hiccups that derail your environmental work.",
	},
	{
		id: "FAQ-2",
		question:
			"Is your platform truly no-code, even for someone like me who’s technically skilled?",
		answer:
			"Yes, it’s 100% no-code, meaning you can build professional-grade map portals without writing a single line of code. Even with your technical background, this frees you up to focus on your science—analyzing ecosystems or collecting data—rather than wrestling with programming or tech stacks, saving you time and effort.",
	},
	{
		id: "FAQ-3",
		question:
			"Can your platform really handle drone data effectively for my environmental maps?",
		answer:
			"Absolutely, it’s built for that. Whether you’re using generating raster photogrammetry from drone flights, or processing LiDAR data into vector outputs for for terrain analysis, Mango integrates your data into maps in seconds. You’ll create detailed, standout maps that highlight environmental insights—perfect for impressing clients or funders with minimal hassle.",
	},
	{
		id: "FAQ-4",
		question:
			"Will my non-technical stakeholders actually understand and use the map portals? ",
		answer:
			"Yes, we’ve designed them with simplicity in mind. Your funders, clients, or community stakeholders—none of whom are GIS pros—can easily navigate and engage with the maps thanks to intuitive layouts and clear visuals. This cuts down on hand-holding time, letting you focus on your mission while they see the value.",
	},
	{
		id: "FAQ-5",
		question:
			"How quickly can I publish a map portal when I’m up against a project deadline?",
		answer:
			"Mango is ready when you are. Always on, always ready to create maps. Go from data to a live portal in hours or days, not weeks. Our streamlined process lets you import data, design your map, and publish it fast—zero delays or bottlenecks. This means you’ll meet tight deadlines, deliver on time, and keep your clients or grant reviewers happy without breaking a sweat.",
	},
	{
		id: "FAQ-6",
		question:
			"Does your platform support long-term updates for ongoing environmental projects?",
		answer:
			"Yes, it’s perfect for long-term needs. You can refresh your map portals with new drone data, field observations, or research updates anytime, without extra fees or technical headaches. This keeps your maps current and relevant, ensuring stakeholders stay engaged and your project’s impact shines over time.",
	},
	{
		id: "FAQ-7",
		question: "Is it better than pricey GIS suites?",
		answer: "Leaner, cheaper, and faster—same impact, less bloat.",
	},
] satisfies { id: string; question: string; answer: string }[];

export function Faqs(): React.JSX.Element {
	return (
		<Box sx={{ bgcolor: "var(--mui-palette-background-level1)", py: "120px" }}>
			<Container maxWidth="md">
				<Stack spacing={8}>
					<Stack
						maxWidth="700px"
						sx={{ mx: "auto" }}>
						<Stack spacing={2}>
							<Box sx={{ display: "flex", justifyContent: "center" }}>
								<Chip
									color="primary"
									icon={<Question />}
									label="FAQ"
									variant="soft"
								/>
							</Box>
							<Typography
								sx={{ textAlign: "center" }}
								variant="h3">
								Questions we get asked
							</Typography>
							<Typography color="text.secondary">
								Have another question you do not see here?{" "}
								<Box
									component="a"
									href="/contact"
									sx={{ color: "inherit", textDecoration: "underline" }}>
									Contact us
								</Box>
								.
							</Typography>
						</Stack>
					</Stack>
					<Stack spacing={2}>
						{faqs.map((faq) => (
							<Faq
								key={faq.id}
								{...faq}
							/>
						))}
					</Stack>
				</Stack>
			</Container>
		</Box>
	);
}

export interface FaqProps {
	answer: string;
	question: string;
}

function Faq({ answer, question }: FaqProps): React.JSX.Element {
	const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

	return (
		<Card sx={{ p: 3 }}>
			<Stack
				onClick={() => {
					setIsExpanded((prevState) => !prevState);
				}}
				sx={{ cursor: "pointer" }}>
				<Stack
					direction="row"
					spacing={2}
					sx={{ alignItems: "center", justifyContent: "space-between" }}>
					<Typography variant="subtitle1">{question}</Typography>
					{isExpanded ? <CaretDown /> : <CaretRight />}
				</Stack>
				<Collapse in={isExpanded}>
					<Typography
						color="text.secondary"
						sx={{ pt: 3 }}
						variant="body2">
						{answer}
					</Typography>
				</Collapse>
			</Stack>
		</Card>
	);
}
