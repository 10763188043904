import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const content = [
	{
		section: "1. What information do we collect?",
		title: "Information you provide us directly",
		description:
			'While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to, your email address, name, and credit card or billing information when you register for a trial Account, checkout of a paid plan, or register as a Partner ("Personal Information"). We may also collect details of any opinions or complaints you raise regarding the Service and details of actions you carry out while using the Service.',
	},
	{
		title: "Information from websites that use our Services",
		description:
			"We collect information when you visit or use third-party websites and apps that use our Services (such as an embedded map). This includes information about the websites, your use of our Services on those websites, as well as information the developer or publisher of the website provides to you or us.",
	},

	{
		title: "Information we may receive from third parties",
		description:
			"We may receive information about you from third parties, including, without limitation, our business partners, third party suppliers, and customers.",
	},
	{
		title: "Cookies",
		description:
			'Cookies are files with a small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. We and our third party partners use "cookies" to automatically collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of the Service.',
	},
	{
		title: "Log data",
		description:
			'We may also collect information that your browser sends whenever you visit our Service ("Log Data"). This Log Data may include information such as your device Internet Protocol ("IP") address, operating system, browser type, browser version, language, time zone, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages and other statistics. In addition, we may use third-party services, such as Google Analytics and Intercom that collect, monitor and analyze this type of information in order to increase functionality of our Service. These third-parties may use cookies to help us analyze how our users are using the Service and they have their own Privacy Policies addressing how they use such information. Features of the Service may require you to opt-in to providing your specific geographic locations, as determined through GPS, Bluetooth, or WiFi signals.',
	},
	{
		section: "2. How we store your information",
		description:
			"Personal Information collected by MangoMap Limited is securely stored on our servers and is not accessible by our employees or third parties except for use as indicated below. It should be noted that the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our site and any transmission is at your own risk. Once we have received your information, we use strict procedures and security features to try to prevent unauthorised access.",
	},
	{
		section: "3. How we use your information",
		title: "Provision of Services",
		description:
			"We use Personal Information to operate, maintain, and provide to you the features and functionality of the Service, as well as to communicate directly with you, such as to send you email messages and push notifications within the Service in pursuit of service improvement, technical support, and research. Personal Information is also used to facilitate identification, authentication, and billing. We may also send you Service-related emails or messages (e.g., account verification, invoices, confirmations, change or updates to features of the Service, technical and security announcements).",
	},
	{
		title: "Marketing and Communications",
		description:
			"We may use your Personal Information to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any or all of these communications from us by following the unsubscribe link or instructions provided in any email we send, or by contacting us. Mango uses remarketing services to advertise on third-party websites to you after you visited our Service. We and our third-party vendors use cookies to inform, optimize and serve ads based on your past visits to our Service.",
	},
	{
		section: "3. Who do we share you information with?",
		title: "Service providers",
		description:
			"We may employ third-party companies and individuals to facilitate our Service, to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used. These third parties have access to your Personal Information only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.",
	},
	{
		title: "Recurly",
		description:
			"Recurly provides subscription management services. Payment information is required to use the Service, and may include your name, address, and credit card information. This information is used solely for billing purposes by our PCI-certified payment provider, Recurly. Your credit card information is encrypted and transmitted to Recurly securely via HTTPS. You can learn more about Recurly’s security practices (https://recurly.com/security/), and Privacy Policy (https://recurly.com/legal/privacy). Your credit card details are never stored on our servers, and no one at MangoMap Limited can access them. Authorized staff involved in customer Account billing may have access to your payment card expiration date to ensure your payment information remains valid. Recurly subscription management is provided by Recurly, Inc.",
	},
	{
		title: "Stripe",
		description:
			"Stripe is a PCI-certified payment gateway. We use Stripe to process credit cards for billing purposes. Please read their Security policy (https://stripe.com/docs/security) and Privacy Policy (https://stripe.com/privacy) for further information. Stripe payment processing is provided by Stripe, Inc.",
	},
	{
		title: "Google Analytics",
		description:
			"You can opt-out from Google Analytics service from using your information by installing the Google Analytics Opt-out Browser tool: (https://tools.google.com/dlpage/gaoptout). For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: (http://www.google.com/policies/privacy/). Google Analytics service is provided by Google Inc.",
	},
	{
		title: "Google",
		description:
			"You can opt-out of Google Analytics for Display Advertising and customize the Google Display Network ads by visiting the Google Ads Settings page: (http://www.google.com/settings/ads). For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: (http://www.google.com/policies/privacy/). Google AdWords remarketing service is provided by Google Inc.",
	},
	{
		title: "Dropbox",
		description:
			"Dropbox is used to provide the Mango Data Sync tool. For information about what type of data Dropbox collects and stores, please visit their Terms (https://www.dropbox.com/terms) and Privacy Policy (https://www.dropbox.com/terms#privacy). Dropbox is provided by Dropbox, Inc.",
	},

	{
		section: "4. How long do we keep your information?",
		description:
			"Following termination or deactivation of your Account, we may retain your profile information and User Data for a commercially reasonable time for backup, archival, or audit purposes.",
	},
	{
		section: "5. Disclosure and compliance with laws",
		description:
			"From time to time, and only in cases where Service delivery or compliance with law enforcement requires, we may disclose your Personal Information to selected third parties, including: in the event that we sell or buy any business or assets, the prospective seller or buyer of such business or assets; if MangoMap Limited or substantially all of its assets are acquired by a third party, to the relevant third party; business parties and subcontractors for the purposes of providing the MangoMap Limited Services; analytics providers that assist us in the improvement and optimization of our website and Service; law enforcement agencies or other third parties: We may disclose your Personal Information where required to do so by law or subpoena, or if we believe that such action is necessary to comply with the law and the reasonable requests of law enforcement, or to protect the security or integrity of our Service.",
	},
	{
		section: "6. What are your privacy rights?",
		description:
			"You have the right to request we not process your Personal Information for marketing purposes. You can exercise your right to prevent such processing by unsubscribing from our emails, newsletters, and other communications. You can exercise this right at any time by contacting us at support@mangomap.com. You have the right to access information held about you. Any access request may be subject to a reasonable fee to meet our costs in providing you details of the Personal Information we hold about you.",
	},
	{
		section: "7. Changes to this Privacy Policy",
		description:
			"We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.",
	},
];

export function Privacy(): React.JSX.Element {
	return (
		<Container
			maxWidth="lg"
			sx={{ py: 4 }}>
			<Typography
				color="text.primary"
				gutterBottom
				variant="h1">
				Privacy Policy
			</Typography>
			<Typography
				color="text.primary"
				component="p"
				gutterBottom
				variant="body1">
				MangoMap Limited (&quot;we&quot;, &quot;us&quot;, &quot;our&quot;) are
				committed to protecting and respecting your privacy and keeping your
				Personal Information secure. This policy, together with our Terms of
				Service and any other documents that they refer to, sets out:
			</Typography>
			<ul>
				<li>
					Details of the Personal Information that we may collect from you;
				</li>
				<li>Information about how we use your Personal Information;</li>
				<li>Information about how we store your Personal Information;</li>
				<li>
					Information about who we may disclose your Personal Information to;
					and
				</li>
				<li>Information about your rights.</li>
			</ul>
			<Typography
				color="text.primary"
				component="p"
				gutterBottom
				variant="body1">
				Please read this policy carefully to understand our views and practices
				regarding your personal data and how we will treat it. Any capitalized
				terms used herein without definition shall have the meaning given to
				them in the Terms of Service. The data controller is MangoMap Limited of
				1 Victoria Street, Bristol, England, BS1 6AA, UK (registered at
				Companies House with company number 08388830 and trading as Mango).{" "}
			</Typography>
			<Typography
				color="text.primary"
				component="p"
				marginBottom={4}
				variant="body1">
				By visiting or using the mangomap.com website or Services in any manner,
				you acknowledge that you accept the practices and policies outlined in
				this Privacy Policy and you hereby consent that we will collect, use,
				and share your information in the following ways.
			</Typography>
			{content.map((content, i) => (
				<Box key={i}>
					<Stack spacing={1}>
						<Typography
							marginTop={4}
							variant="h3">
							{content.section}
						</Typography>
						<Typography variant="h5">{content.title}</Typography>
						<Typography
							color="text.primary"
							variant="body1">
							{content.description}
						</Typography>
					</Stack>
				</Box>
			))}

			<Stack spacing={1}>
				<Box
					boxShadow={0}
					component={Card}>
					<Box
						paddingBottom={{ xs: 0, md: 3 }}
						paddingTop={3}
						paddingX={{ xs: 0, md: 3 }}>
						<Typography
							gutterBottom
							sx={{
								fontWeight: "700",
							}}>
							How can you contact us about this notice?
						</Typography>
						<Typography
							color="textSecondary"
							sx={{
								marginBottom: 2,
							}}
							variant="body2">
							If you have any questions or concerns about this security
							information please contact us.
						</Typography>
						<Typography variant="subtitle2">
							support@mangomap.com
							<br />
							1 Victoria Street
							<br />
							Bristol, BS1 6AA
							<br />
							United Kingdom
						</Typography>
					</Box>
				</Box>
			</Stack>
		</Container>
	);
}
